import React from "react";
import { useParams } from "react-router";
import { Section } from "src/Components/Common/Section";
import { ChapmanSchedule } from "./Chapman";
import { BrookKrausSchedule } from "./BrookKraus";

export const ScheduleRouter = () => {
  const { scheduleId } = useParams() as {
    scheduleId?: string;
  };

  return (
    <div className={"TalkDescription"}>
      {scheduleId === "Chapman" ? (
        <ChapmanSchedule />
      ) : scheduleId === "BrookKraus-May2024" ? (
        <BrookKrausSchedule />
      ) : (
        <Section title="Which schedule?">
          We'd love to show the schedule you're looking for, but it doesn't seem
          to be here. Reach out and let us know
          <a href="mailto:info@epbne.com" target="_blank">
            info@ebpne.com
          </a>
        </Section>
      )}
    </div>
  );
};
