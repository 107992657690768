import React from "react";
import { useParams } from "react-router";
import { Section } from "src/Components/Common/Section";
import { CPTandPE } from "./CPTandPE";
import { Wisniewski } from "./Wisniewski";
import { Sayrs } from "./Sayrs";
import { Chriki } from "./Chriki";
import { Tomcik } from "./Tomcik";
import { SayrsTeamEffectiveness } from "./Sayrs-TeamEffectiveness";
import { TomcikPortner } from "./TomcikPortner";
import { Harned } from "./Harned";
import { Conley } from "./Conley";
import { Perepletchikova } from "./Perepletchikova";
import { ChrikiDec2021 } from "./Chriki-Dec2021";
import { Fitzpatrick } from "./Fitzpatrick";
import { Ritschel } from "./Ritschel";
import { TomcikPortnerSchedule } from "./TomcikPortnerSchedule";
import { PublicTalks } from "../PublicTalks";
import { ChrikiSept2022Details } from "./Chriki-Sept2022";
import { ChrikiSept2022Session1Details } from "./Chriki-Sept2022-Sess1";
import { ChrikiSept2022Session2Details } from "./Chriki-Sept2022-Sess2";
import { ChrikiSept2022Session328thDetails } from "./Chriki-Sept2022-Sess3-28th";
import { ChrikiSept2022Session326thDetails } from "./Chriki-Sept2022-Sess3-26th";
import { ChrikiSept2022Session4Details } from "./Chriki-Sept2022-Sess4";
import { BergerDetails } from "./Berger";
import { ChapmanDetails } from "./Chapman";
import { MorrisonDetails } from "./Morrison";
import { JohnsonDetails } from "./Johnson";
import { BrookKrausDetails } from "./BrookKraus";
import { BrookKrausMay2024 } from "./BrookKraus-May2024";
import { ChrikiAug2024 } from "./Chriki-Aug2024";

export const TalkDescription = ({ talkId }: { talkId?: string }) => {
  const { routeTalkId, routeTalkDetails } = useParams() as {
    routeTalkId?: string;
    routeTalkDetails?: string;
  };

  return (
    <div className={"TalkDescription"}>
      {routeTalkId === "CPTandPE" ? (
        <CPTandPE />
      ) : routeTalkId === "Wisniewski" ? (
        <Wisniewski />
      ) : routeTalkId === "Sayrs" ? (
        <Sayrs />
      ) : routeTalkId === "SayrsTeamEffectiveness" ? (
        <SayrsTeamEffectiveness />
      ) : routeTalkId === "Berger" ? (
        <BergerDetails />
      ) : routeTalkId === "BrookKraus" ? (
        <BrookKrausDetails />
      ) : routeTalkId === "BrookKraus-May2024" ? (
        <BrookKrausMay2024 />
      ) : routeTalkId === "Chapman" ? (
        <ChapmanDetails />
      ) : routeTalkId === "Chriki" ? (
        <Chriki />
      ) : routeTalkId === "ChrikiDec2021" ? (
        <ChrikiDec2021 />
      ) : routeTalkId === "Chriki-Aug2024" ? (
        <ChrikiAug2024 />
      ) : routeTalkId === "ChrikiSept2022" ? (
        <ChrikiSept2022Details />
      ) : routeTalkId === "ChrikiSept2022-Session1" ? (
        <ChrikiSept2022Session1Details />
      ) : routeTalkId === "ChrikiSept2022-Session2" ? (
        <ChrikiSept2022Session2Details />
      ) : routeTalkId === "ChrikiSept2022-Session3-26th" ? (
        <ChrikiSept2022Session326thDetails />
      ) : routeTalkId === "ChrikiSept2022-Session3-28th" ? (
        <ChrikiSept2022Session328thDetails />
      ) : routeTalkId === "ChrikiSept2022-Session4" ? (
        <ChrikiSept2022Session4Details />
      ) : routeTalkId === "Johnson" ? (
        <JohnsonDetails />
      ) : routeTalkId === "Tomcik" ? (
        <Tomcik />
      ) : routeTalkId === "TomcikPortner" ? (
        <TomcikPortner />
      ) : routeTalkId === "Harned" ? (
        <Harned />
      ) : routeTalkId === "Conley" ? (
        <Conley />
      ) : routeTalkId === "Morrison" ? (
        <MorrisonDetails />
      ) : routeTalkId === "Perepletchikova" ? (
        <Perepletchikova />
      ) : routeTalkId === "Fitzpatrick" ? (
        <Fitzpatrick />
      ) : routeTalkId === "Ritschel" ? (
        <Ritschel />
      ) : routeTalkId === undefined ? (
        <PublicTalks />
      ) : (
        <Section title="Which talk?">
          We'd love to show the talk you're looking for, but we either have given it or haven't gotten it on the site
          yet. Reach out and let us know
          <a href="mailto:info@epbne.com" target="_blank">
            info@ebpne.com
          </a>
        </Section>
      )}
    </div>
  );
};
