import React from "react";

export const ContinuingEdCreditTag = ({
  numberOfCredits,
}: {
  numberOfCredits: number;
}) => {
  return (
    <div className={"ContinuingEdTag"}>
      {numberOfCredits} CE credit{numberOfCredits === 1 ? "" : "s"}
    </div>
  );
};
