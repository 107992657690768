import React from "react";
import logo from "./logo.svg";
import "./Styles/App.scss";
import { BrowserRouter } from "react-router-dom";
import { TalksGroup } from "./Components/TalksGroup";

function App() {
  return (
    <BrowserRouter>
      <TalksGroup />
    </BrowserRouter>
  );
}

export default App;
