import React from "react";
import BergerImg from "src/assets/Berger.jpg";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const title = `SPACE - Failure To Launch`;
const subtitle =
  "Parental guidance for parents of individuals who failed to launch into adulthood";

const lowCost = {
  cost: 25,
  link: "https://buy.stripe.com/14k6qzdah4J7fdKcNE",
};
const highCost = {
  cost: 45,
  link: "https://buy.stripe.com/3csaGP3zH5Nb1mU9Bt",
};

const startTime: UsefulDate = {
  time: 12,
  month: "December",
  date: "12th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "December",
  date: "12th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img
    src={BergerImg}
    alt={"Dr. Berger"}
    className={"heightBased"}
    style={{ marginLeft: "-30px" }}
  />
);

export const BergerPromo = () => {
  return (
    <HomeTalkPromo
      link={"Berger"}
      speakers={[
        {
          name: "​Uri Berger, Ph.D., M.A.",
          image: <div>{speakerImage}</div>,
          affiliation:
            "Yale Child Study Center Anxiety and Mood Disorders Program",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This webinar will explore the topic of Failure to Launch (FTL) and an evidence-based treatment (SPACE) aimed at helping those struggling to build independence as adults."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const BergerDetails = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "​Uri Berger, Ph.D., M.A.",
          extraTitling: "Postdoctoral Associate",
          image: <div>{speakerImage}</div>,
          affiliation:
            "Yale Child Study Center Anxiety and Mood Disorders Program",
          about: (
            <div>
              Dr. Berger is a clinical psychologist. He received his doctoral
              degree in Psychology from Bar-Ilan University. His M.A. degrees in
              psychology (experimental and clinical) are from the universities
              of Bar-Ilan and Tel-Aviv. Currently, Dr. Berger is working at
              Yale's Child Study Center on a project investigating highly
              dependent adult children and provide consultation to patients and
              families.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Failure to launch (FTL) has been used to describe abled adults who do
          not work, do not attend school, and live with- and at the expense of
          —their parents. FTL can be beneficially addressed through parent
          training. Dr. Berger will first review what is known about the FTL
          condition and how it differs from similar conditions. He will then
          present a new parent training approach for parents of individuals with
          FTL, based on SPACE (Supportive Parenting for Anxious Childhood
          Emotions).
        </div>
      }
      educationalObjectives={[
        "Describe the FTL condition and differentiate it from other similar conditions",
        "Describe the five key components of SPACE for parents of adults with FTL",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate to Advanced",
        description:
          "This workshop is intended for participants who have had moderate experience working with this population and, ideally, have a background in parental guidance",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
