import React from "react";
import RitschelImg from "src/assets/Ritschel.png";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

export const RitschelPromo = () => {
  return (
    <HomeTalkPromo
      link={"Ritschel"}
      speakers={[
        {
          name: "Lorie Ritschel, Ph.D.",
          image: (
            <div style={{ marginTop: "-30px" }}>
              <img src={RitschelImg} alt={"Lorie Ritschel"} />
            </div>
          ),
          affiliation:
            "Triangle Area Psychology Clinic & UNC School of Medicine",
          about: (
            <div>
              Dr. Ritschel earned her doctorate in Clinical Psychology from the
              University of Kansas, and she completed postdoctoral fellowships
              at Duke University Medical Center and Emory University School of
              Medicine. She is a faculty member in the department of psychiatry
              of the University of North Carolina at Chapel Hill School of
              Medicine, where she directs the CBT training program for
              psychiatry residents. Dr. Ritschel is an expert trainer and
              consultant of DBT and the DBT Prolonged Exposure protocol through
              Behavioral Tech, LLC. She is a board certified DBT therapist
              through the DBT-Linehan Board of Certification.
              <br />
              <br />
              She provides training and consultation in CBT, DBT, PE, and
              Behavioral Activation as well as educational trainings in
              self-injury, suicide, borderline personality disorder, mood
              disorders, mindfulness, and hope. Dr. Ritschel is a co-owner of
              the Triangle Area Psychology Clinic, an outpatient private
              practice in Durham, NC where she and her colleagues provided a
              wide range of empirically supported treatments for depression,
              anxiety, trauma, and emotion dysregulation in adolescents and
              adults.
            </div>
          ),
        },
      ]}
      title="DBT-PE with Adolescents"
      subtitle="Special considerations"
      start={{ time: 12, month: "July", date: "25th", year: 2022 }}
      end={{ time: 13.5, month: "July", date: "25th", year: 2022 }}
      miniAbout={
        "This 1.5 hour webinar instills how to use CPA for helping chilfren with self-love, safety and belonging."
      }
      numberOfCredits={1.5}
      purchaseOptions={{
        highCost: {
          cost: 45,
          link: "https://buy.stripe.com/dR602bfipdfDc1yfZt",
        },
        lowCost: {
          cost: 25,
          link: "https://buy.stripe.com/14keX56LT5Nbe9G9B6",
        },
      }}
    />
  );
};

export const Ritschel = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lorie Ritschel, Ph.D.",
          image: <img src={RitschelImg} alt={"Lorie Ritschel"} />,
          affiliation:
            "Triangle Area Psychology Clinic & UNC School of Medicine",
          about: (
            <div>
              Dr. Ritschel earned her doctorate in Clinical Psychology from the
              University of Kansas, and she completed postdoctoral fellowships
              at Duke University Medical Center and Emory University School of
              Medicine. She is a faculty member in the department of psychiatry
              of the University of North Carolina at Chapel Hill School of
              Medicine, where she directs the CBT training program for
              psychiatry residents. Dr. Ritschel is an expert trainer and
              consultant of DBT and the DBT Prolonged Exposure protocol through
              Behavioral Tech, LLC. She is a board certified DBT therapist
              through the DBT-Linehan Board of Certification.
              <br />
              <br />
              She provides training and consultation in CBT, DBT, PE, and
              Behavioral Activation as well as educational trainings in
              self-injury, suicide, borderline personality disorder, mood
              disorders, mindfulness, and hope. Dr. Ritschel is a co-owner of
              the Triangle Area Psychology Clinic, an outpatient private
              practice in Durham, NC where she and her colleagues provided a
              wide range of empirically supported treatments for depression,
              anxiety, trauma, and emotion dysregulation in adolescents and
              adults.
            </div>
          ),
        },
      ]}
      title="DBT-PE with Adolescents"
      subtitle="Special considerations"
      start={{ time: 12, month: "July", date: "25th", year: 2022 }}
      end={{ time: 13.5, month: "July", date: "25th", year: 2022 }}
      aboutSection={
        <div>
          In this workshop, Dr. Ritschel will address issues that are unique to
          conducting trauma treatment with adolescents using the DBT PE
          protocol. This workshop is designed for practitioners who are
          currently conducting DBT PE with adolescents or who are planning to do
          so soon and are interested in thinking through some of the nuanced
          issues that arise in providing trauma treatment with this population.
          Dr. Ritschel will provide a brief overview of the treatment and
          summarize the data to date. The workshop will focus on issues unique
          to adolescents with PTSD, such as:
          <ul>
            <li>
              Involving families in treatment: getting consent, making
              disclosures, orienting to treatment, and leveraging family to
              support the treatment
            </li>
            <li>Assessing readiness for treatment</li>
            <li>Issues to consider with risk and reporting</li>
            <li>Working with kids who aren’t ready or willing to treat PTSD</li>
          </ul>
        </div>
      }
      educationalObjectives={[
        "List at least three special issues that are important to consider when conducting DBT-PE with adolescents",
        "Describe core markers of readiness for DBT-PE treatment in adolescents",
        "Identify special concerns regarding risk and reporting when conducting DBT-PE with adolescents",
      ]}
      numberOfCredits={1.5}
      instructionLevel={{
        level: "Intermediate",
        description: "have familiarity with DBT-PE and DBT-A",
      }}
      purchaseOptions={{
        highCost: {
          cost: 45,
          link: "https://buy.stripe.com/dR602bfipdfDc1yfZt",
        },
        lowCost: {
          cost: 25,
          link: "https://buy.stripe.com/14keX56LT5Nbe9G9B6",
        },
      }}
    />
  );
};
