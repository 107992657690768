import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import SayrsImg from "src/assets/Sayrs.jpeg";

const title = "DBT Teams";
const subtitle =
  "Overcoming Struggles to MaStructuring your team to support effective DBTintain the Effectiveness of the Team";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "July",
  date: "12th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "July",
  date: "12th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = <img src={SayrsImg} alt={"Jennifer Sayrs"} />;

export const Sayrs = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Jennifer Sayrs, Ph.D., ABPP",
          image: <div>{speakerImage}</div>,
          affiliation: "Evidence Based Treatment Centers of Seattle (EBTCS)",
          about: (
            <div>
              <div>
                Jennifer Sayrs, PhD, ABPP is the Executive Director and
                co-founder of the Evidence Based Treatment Centers of Seattle
                (EBTCS). At EBTCS, she directs the DBT Center, one of the first
                DBT programs in the nation to be certified by the Linehan Board
                of Certification. She is the co-author of the book DBT Teams:
                Development and Practice, written with the DBT treatment
                developer, Dr. Marsha Linehan.
              </div>
              <br />
              <div>
                Dr. Sayrs completed her graduate training at the University of
                Nevada, Reno, where she studied DBT and behavioral theory,
                followed by a postdoctoral fellowship with Dr. Linehan at the
                University of Washington, where she was the clinical coordinator
                for Dr. Linehan’s DBT research clinic. She has served as a
                research therapist and adherence coder on several DBT trials;
                she is also a trainer for Behavioral Tech, providing a wide
                range of DBT workshops around the world. In addition to 25 years
                of experience providing DBT, she also provides CBT for a variety
                of anxiety-related problems. Dr. Sayrs is certified by the
                Linehan Board of Certification and the American Board of
                Cognitive and Behavioral Psychology and is a licensed
                psychologist in the state of Washington.
              </div>
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Dialectical behavior therapy includes several components that meet
          different treatment functions. During this webinar we will discuss how
          to run an effective DBT team including the ways DBT team is different
          from traditional treatment teams, the team agreements and roles, and
          the basic structure of the team meeting. We will then focus on common
          challenges teams face and explore possible solutions.
        </div>
      }
      educationalObjectives={[
        "Identify the function of the DBT Consultation Team",
        "Identify strategies for running an effective DBT",
        "Describe the structure of the DBT team, team agreements, and member roles",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is intended for those who have some familiarity with DBT, are seeking to know more about DBT consultation teams, are on a team, or are looking to form a team.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
