import React, { useState } from "react";
import { Speaker, WatchRecordingPrompt } from "../Common/NEW/DetailsPage";
import { StyledButton } from "../Common/StyledButton";
import SearchIcon from "src/assets/icon_search.svg";
import RitschelImg from "src/assets/Ritschel.png";
import TomcikImg from "src/assets/Tomcik.png";
import PortnerImg from "src/assets/Portner.jpeg";
import ConleyImg from "src/assets/Conley.png";
import HarnedImg from "src/assets/Harned.jpeg";
import PerepletchikovaImg from "src/assets/Perepletchikova.jpeg";
import FitzpatrickImg from "src/assets/Fitzpatrick.png";
import ChrikiImg from "src/assets/Lyvia.jpg";
import LuceneImg from "src/assets/Lucene.jpg";
import SayrsImg from "src/assets/Sayrs.jpeg";
import BergerImg from "src/assets/Berger.jpg";
import MorrisonImg from "src/assets/Morrison.jpg";
import JohnsonImg from "src/assets/Johnson.jpeg";
import ChapmanImg from "src/assets/Chapman-Alex.jpg";
import BrookImg from "src/assets/Brook.jpg";
import KrausImg from "src/assets/Kraus.jpeg";

type PreviousTalkInfoType = {
  link: string;
  speakers: Speaker[];
  title: string;
  subtitle?: string;
  miniAbout: string;
  videoLink?: string;
};
const previousTalksList: PreviousTalkInfoType[] = [
  //May 2024
  {
    link: "BrookKraus-May2024",
    speakers: [
      {
        name: "Amara Brook, Ph.D., ABPP",
        image: <img src={BrookImg} alt={"Dr. Brook"} />,
        affiliation: "Flow Psychological Services, San Jose, CA",
        about: <div />,
      },
      {
        name: "Rachel Leah Kraus, LCSW-C",
        image: <img src={KrausImg} alt={"Dr. Kraus"} />,
        affiliation: "Kraus Psychotherapy, LLC",
        about: <div />,
      },
    ],
    title: "Full Day Training ~ Tailoring DBT to be more Effective and Affirming for Autistic Clients",
    subtitle: "",
    miniAbout: "This day-long training provided a deep dive on how to tailor DBT for Autistic clients.",
  },
  //Feb 2024
  {
    link: "BrookKraus",
    speakers: [
      {
        name: "Amara Brook, Ph.D., ABPP",
        image: <img src={BrookImg} alt={"Dr. Brook"} />,
        affiliation: "Flow Psychological Services, San Jose, CA",
        about: <div />,
      },
      {
        name: "Rachel Leah Kraus, LCSW-C",
        image: <img src={KrausImg} alt={"Dr. Kraus"} />,
        affiliation: "Kraus Psychotherapy, LLC",
        about: <div />,
      },
    ],
    title: "Tailoring DBT to be More Effective and Affirming for Autistic Clients",
    subtitle: "",
    miniAbout:
      "This webinar discussed differences between the deficit-based model and the neurodiversity model, traits of Autistic clients, and how to modify DBT to optimally help Autistic clients.",
  },
  //April 2023
  {
    link: "Chapman",
    speakers: [
      {
        name: "Alexander L. Chapman, Ph.D., R.Psych",
        image: (
          <div>
            <img src={ChapmanImg} alt={"Dr. Chapman"} />
          </div>
        ),
        affiliation: "Simon Fraser University & DBT Centre of Vancouver, Inc.",
        about: <div />,
      },
    ],
    title: "Sustainable Phone Coaching in DBT",
    subtitle: "Managing Clinical Challenges and Suicide Risk Effectively",
    miniAbout:
      "This workshop aimed to help clinicians learn how to effectively navigate and sustain phone coaching in their practice and to deal with common challenges. This event will not be recorded.",
  },
  {
    link: "Johnson",
    speakers: [
      {
        name: "Daniel Johnson, Ph.D.",
        image: (
          <div>
            <img src={JohnsonImg} alt={"Daniel Johnson, Ph.D."} />
          </div>
        ),
        affiliation: "",
        about: <div />,
      },
    ],
    title: "Getting to Acceptance",
    subtitle:
      "Working with Difficult Emotions 'in the room' Using Principles from Exposure, Inhibitory Learning, and Mindfulness-Based Treatments",
    miniAbout:
      "This workshop discussed strategies for promoting acceptance of difficult thoughts and feelings in sessions for both clients and clinicians.",
  },
  //Mar 2023
  {
    link: "Morrison",
    speakers: [
      {
        name: "Eleshia Morrison, Ph.D., L.P., ABPP",
        image: (
          <div>
            <img src={MorrisonImg} alt={"Eleshia Morrison, Ph.D., L.P., ABPP"} />
          </div>
        ),
        affiliation: "Mayo Clinic College of Medicine and Science",
        about: <div />,
      },
    ],
    title: "Going Beyond The Pain",
    subtitle: "Pain Neuroscience Education as a Strategy for Helping Patients with Chronic Pain",
    miniAbout: "This webinar introduced basics of using PNE to help individuals with chronic pain.",
  },

  //Dec 2022
  {
    link: "Berger",
    speakers: [
      {
        name: "​Uri Berger, Ph.D., M.A.",
        image: (
          <div>
            <img src={BergerImg} alt={"Dr. Berger"} className={"heightBased"} style={{ marginLeft: "-30px" }} />
          </div>
        ),
        affiliation: "Yale Child Study Center Anxiety and Mood Disorders Program",
        about: <div />,
      },
    ],
    title: "SPACE - Failure To Launch",
    subtitle: "Parental guidance for parents of individuals who failed to launch into adulthood",
    miniAbout:
      "This webinar explored the topic of Failure to Launch (FTL) and an evidence-based treatment (SPACE) aimed at helping those struggling to build independence as adults.",
  },

  //SEPT / OCT 2022
  {
    link: "ChrikiSept2022",
    speakers: [
      {
        name: "Lyvia Chriki, Ph.D.",
        image: <img src={ChrikiImg} alt={"Dr. Chriki"} className={"heightBased"} style={{ marginLeft: "-10px" }} />,
        affiliation: "Boston Evidence Supported Treatments & EBPNE",
        about: <div />,
      },
    ],
    title: "Parent Guidance in DBT Series",
    subtitle: "A Workshop Series Drawing on the Data and Interventions We Know",
    miniAbout:
      "This 4-part series helped clinicians gain a sense of direction and learn strategies to help bolster their work with parents of adolescents and young adults within the DBT model.",
  },

  //JULY
  {
    link: "Ritschel",
    speakers: [
      {
        name: "Lorie Ritschel, Ph.D.",
        image: (
          <div style={{ marginTop: "-20px" }}>
            <img src={RitschelImg} alt={"Lorie Ritschel"} />
          </div>
        ),
        affiliation: "Triangle Area Psychology Clinic & UNC School of Medicine",
        about: <div />,
      },
    ],
    title: "DBT-PE with Adolescents",
    subtitle: "Special Considerations",
    miniAbout:
      "This 1.5 hour webinar instilled how to use CPA for helping chilfren with self-love, safety and belonging.",
  },

  //JUNE
  {
    link: "Fitzpatrick",
    speakers: [
      {
        name: "Skye Fitzpatrick, Ph.D.",
        image: <img src={FitzpatrickImg} alt={"Skye Fitzpatrick"} />,
        affiliation: "York University",
        about: <div />,
      },
    ],
    title: "Emotion Dysregulation as an Interpersonal Process",
    subtitle: "Introducing a Novel Model and Intervention for Borderline Personality Disorder",
    miniAbout:
      "This 1.5 hour webinar instilled how to use CPA for helping chilfren with self-love, safety and belonging.",
  },

  //MAY
  {
    link: "TomcikPortner",
    speakers: [
      {
        name: "Nathan Tomcik, Ph.D.",
        image: <img src={TomcikImg} alt={"Nathan Tomcik"} />,
        affiliation: "Behavioral Health VA Central Ohio Healthcare System",
        about: <div />,
      },
      {
        name: "Jimmy Portner, LISW-S",
        image: <img src={PortnerImg} alt={"Jimmy Portner"} />,
        affiliation: "VA of Central Ohio Healthcare System",
        about: <div />,
      },
    ],
    title: "RO-DBT for Disorders of Overcontrol",
    subtitle: "Introductory Training",
    miniAbout: "This 6 hour training was an introduction to RO-DBT treatment.",
  },

  //FEBRUARY
  {
    link: "Perepletchikova",
    speakers: [
      {
        name: "Francheska Perepletchikova, Ph.D.",
        image: <img src={PerepletchikovaImg} alt={"Francheska Perepletchikova"} />,
        affiliation: "Youth Dialectical-Behavioral Program",
        about: <div />,
      },
    ],
    title: "Core Problem Analysis",
    subtitle: "Addressing internal controlling factors within the DBT-C model",
    miniAbout:
      "This 1.5 hour webinar instilled how to use CPA for helping children with self-love, safety, and belonging.",
  },

  //FEBRUARY
  {
    link: "Harned",
    speakers: [
      {
        name: "Melanie Harned, Ph.D.",
        image: <img src={HarnedImg} alt={"Melanie Harned"} />,
        affiliation: "VA Puget Sound & University of Washington",
        about: <div />,
      },
    ],
    title: "Addressing Complicated Issues in Trauma Treatment",
    subtitle: "Dissociation and Fragmented Trauma Memories",
    miniAbout: "This 1.5 hour webinar was an advanced exploration on treating PTSD among clients with complexities.",
  },

  //JANUARY
  {
    link: "Conley",
    speakers: [
      {
        name: "Christopher Conley, M.S.W.",
        image: <img src={ConleyImg} alt={"Christopher Conley"} />,
        affiliation: "McMaster University",
        about: <div />,
      },
    ],
    title: "“I don’t think you actually have a client yet!”",
    subtitle: "Pre-treatment and commitment in DBT",
    miniAbout:
      "This 3 hour workshop discussed processes, tasks, and challenges in the four pre-treatment sessions when getting a new client started in DBT.",
  },

  //DECEMBER
  {
    link: "ChrikiDec2021",
    speakers: [
      {
        name: "Lyvia Chriki, Ph.D.",
        image: <img src={ChrikiImg} alt={"Dr. Chriki"} className={"heightBased"} style={{ marginLeft: "-10px" }} />,
        affiliation: "Boston Evidence Supported Treatments & EBPNE",
        about: <div />,
      },
    ],
    title: "“Why is my child acting this way!?!”",
    subtitle: "Parent guidance through the lens of dialectical dilemmas",
    miniAbout:
      "This 1.5 hour webinar will helped clinicians learn to conceptualize parent guidance cases through the lens of dialectical dilemmas.",
  },

  //OCTOBER
  {
    link: "Tomcik",
    speakers: [
      {
        name: "Nathan Tomcik, Ph.D.",
        image: <img src={TomcikImg} alt={"Nathan Tomcik"} />,
        affiliation: "Behavioral Health VA Central Ohio Healthcare System",
        about: <div />,
      },
    ],
    title: "Integrative Behavioral Couples Therapy",
    subtitle: "A novel empirically based approach to helping couples in distress",
    miniAbout: "This 1.5 hour webinar was an introduction to IBCT principles and practices.",
  },
  {
    link: "SayrsTeamEffectiveness",
    speakers: [
      {
        name: "Jennifer Sayrs, Ph.D.",
        image: <img src={SayrsImg} alt={"Jennifer Sayrs"} />,
        affiliation: "Evidence Based Treatment Centers of Seattle (EBTCS)",
        about: <div />,
      },
    ],
    title: "DBT Consultation Team Effectiveness",
    subtitle: "Overcoming Struggles to Maintain the Effectiveness of the Team",
    miniAbout: "This 1.5 hour webinar was a deeper dive on struggles faced whilekeeping a DBT team effective.",
  },

  // AUGUST
  {
    link: "Chriki",
    speakers: [
      {
        name: "Lyvia Chriki, Ph.D.",
        image: <img src={ChrikiImg} alt={"Dr. Chriki"} className={"heightBased"} style={{ marginLeft: "-10px" }} />,
        affiliation: "Boston Evidence Supported Treatments & EBPNE",
        about: <div />,
      },
    ],
    title: "Parent Consultation & Guidance in DBT",
    subtitle: "Helping parents provide a validating and stable environment",
    miniAbout:
      "This 1.5 hour webinar taught strategies on providing effective guidance for parents of adolescents and young adults.",
  },

  //JULY
  {
    link: "Sayrs",
    speakers: [
      {
        name: "Jennifer Sayrs, Ph.D.",
        image: <img src={SayrsImg} alt={"Jennifer Sayrs"} />,
        affiliation: "Evidence Based Treatment Centers of Seattle (EBTCS)",
        about: <div />,
      },
    ],
    title: "DBT Teams",
    subtitle: "Structuring your team to support effective DBT",
    miniAbout: "This 1.5 hour webinar focused on the DBT team.",
  },

  //JUNE
  {
    link: "Wisniewski",
    speakers: [
      {
        name: "Lucene Wisniewski, Ph.D.",
        image: <img src={LuceneImg} alt={"Lucene Wisniewski"} />,
        affiliation: "The Center for Evidence Based Treatment – Ohio & Case Western Reserve University",
        about: <div />,
      },
    ],
    title: "Where Angels Fear to Tread",
    subtitle: "When your ED Client is Engaging in Behaviors that Interfere with Treatment",
    miniAbout:
      "This 1 hour talk reviewed the concept of therapy interfering behaviors and provided strategies to help traverse this complicated territory.",
  },

  //FIRST
  {
    link: "CPTandPE",
    speakers: [
      {
        name: "Lyvia Chriki, Ph.D.",
        image: <img src={ChrikiImg} alt={"Dr. Chriki"} className={"heightBased"} style={{ marginLeft: "-10px" }} />,
        affiliation: "Boston Evidence Supported Treatments & EBPNE",
        about: <div />,
      },
    ],
    title: "CPT & PE",
    subtitle: "Our first webinar",
    miniAbout: "Drs. Greene and Chriki discussed CPT & PE.",
  },
];

const OneTalk = ({ link, speakers, title, subtitle, miniAbout, videoLink }: PreviousTalkInfoType) => {
  return (
    <div className="oneTalk">
      <div className="detailsBody">
        <div className="speakersArea">
          {speakers.map((speaker, ind) => (
            <div key={speaker.name + ind} className="individualSpeaker">
              <div>
                <div className={`speakerImage ${!!speaker.smallImage ? "small" : ""}`}>{speaker.image}</div>
                <div className="speakerName">{speaker.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex1">
          <div className="aboutArea">
            <div className="talkTitle">
              {title}{" "}
              <div className="authorOnPhone">
                with{" "}
                {speakers.map((speaker, ind) => (
                  <div key={speaker.name + ind}>
                    {speaker.name} {ind !== speakers.length - 1 && <span>and</span>}
                  </div>
                ))}
              </div>
            </div>
            {!!subtitle && <div className="talkSubtitle">{subtitle}</div>}
            <div className="aboutTheTalk">
              <div>{miniAbout}</div>
            </div>
          </div>

          <div className="buttonRow">
            <StyledButton internalLink={`talks/${link}`} classname="clearWhiteEdge">
              LEARN MORE
            </StyledButton>
            {videoLink ? <WatchRecordingPrompt link={videoLink} altClassname={"clearWhiteEdge"} /> : <div></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PreviousTalks = () => {
  const [searchContent, setSearchContent] = useState<string>("");

  const searchInLowerCase = searchContent.toLocaleLowerCase();
  return (
    <div className="previousTalks">
      <div className="innerWidth">
        <div className="title">
          <span>PREVIOUS SESSIONS</span>
          <input
            type={"text"}
            value={searchContent}
            name={"previousTalksSearch"}
            placeholder={"Filter list..."}
            onChange={(e) => setSearchContent(e.target.value)}
          />
          <img src={SearchIcon} className={"searchIcon"} alt="" />
        </div>
        <div className="talksList">
          {previousTalksList
            .filter(
              (previousTalkInfo) =>
                previousTalkInfo.speakers.some((speaker) =>
                  speaker.name.toLocaleLowerCase().includes(searchInLowerCase)
                ) || previousTalkInfo.title.toLocaleLowerCase().includes(searchInLowerCase)
            )
            .map((previousTalkInfo) => (
              <OneTalk key={previousTalkInfo.title} {...previousTalkInfo} />
            ))}
        </div>
      </div>
    </div>
  );
};
