import React from "react";
import BrookImg from "src/assets/Brook.jpg";
import KrausImg from "src/assets/Kraus.jpeg";
//import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";

const title = `Full Day Training ~ Tailoring DBT to be more Effective and Affirming for Autistic Clients`;
const subtitle = "";

const lowCost = {
  cost: 90,
  link: "https://buy.stripe.com/5kAcOX2vD3F3c1y01a",
};
const highCost = {
  cost: 175,
  link: "https://buy.stripe.com/3cs2ajgmtfnLd5C8xH",
};

const startTime: UsefulDate = {
  time: 11,
  month: "May",
  date: "17th",
  year: 2024,
};
const endTime: UsefulDate = {
  time: 17,
  month: "May",
  date: "17th",
  year: 2024,
};

const creditAmount = 5;

const speaker1Name = "Amara Brook, Ph.D., ABPP";
const speaker1Image = <img src={BrookImg} alt={"Dr. Brook"} />;
const speaker1Affiliation = "Flow Psychological Services, San Jose, CA					";

const speaker2Name = "Rachel Leah Kraus, LCSW-C";
const speaker2Image = <img src={KrausImg} alt={"Dr. Kraus"} />;
const speaker2Affiliation = "Kraus Psychotherapy, LLC";

const suggestedReadings = [
  "Bemmouna, D., Coutelle, R., Weibel, S., & Weiner, L. (2022). Feasibility, Acceptability and Preliminary Efficacy of Dialectical Behavior Therapy for Autistic Adults without Intellectual Disability: A Mixed Methods Study. Journal of autism and developmental disorders, 52(10), 4337–4354. https://doi.org/10.1007/s10803-021-05317-w",
  "Blanchard A, Chihuri S, DiGuiseppi CG, Li G. (2021). Risk of Self-harm in Children and Adults With Autism Spectrum Disorder: A Systematic Review and Meta-analysis. JAMA Netw Open., 4(10):e2130272. doi:10.1001/jamanetworkopen.2021.30272",
  "Cassidy, S. A., Gould, K., Townsend, E., Pelton, M., Robertson, A. E., & Rodgers, J. (2020). Is Camouflaging Autistic Traits Associated with Suicidal Thoughts and Behaviours? Expanding the Interpersonal Psychological Theory of Suicide in an Undergraduate Student Sample. Journal of autism and developmental disorders, 50(10), 3638–3648. https://doi.org/10.1007/s10803-019-04323-3",
  "Hedley, D., Uljarević, M. (2018). Systematic Review of Suicide in Autism Spectrum Disorder: Current Trends and Implications. Curr Dev Disord Rep 5, 65–76. https://doi.org/10.1007/s40474-018-0133-6",
  "Mazefsky, C.A. (2015). Emotion Regulation and Emotional Distress in Autism Spectrum Disorder: Foundations and Considerations for Future Research. J Autism Dev Disord 45, 3405–3408. https://doi.org/10.1007/s10803-015-2602-7",
  "Ritschel, L. A., Guy, L., & Maddox, B. B. (2022). A pilot study of dialectical behaviour therapy skills training for autistic adults. Behavioural and cognitive psychotherapy, 50(2), 187–202. https://doi.org/10.1017/S1352465821000370",
  "Segers, M., & Rawana, J. (2014). What do we know about suicidality in autism spectrum disorders? A systematic review. Autism research : Official journal of the International Society for Autism Research, 7(4), 507–521. https://doi.org/10.1002/aur.1375 ",
];

export const BrookKrausMay2024Promo = () => {
  return (
    <HomeTalkPromo
      link={"BrookKraus-May2024"}
      speakers={[
        {
          name: speaker1Name,
          image: <div>{speaker1Image}</div>,
          affiliation: speaker1Affiliation,
          about: <div />,
        },
        {
          name: speaker2Name,
          image: <div>{speaker2Image}</div>,
          affiliation: speaker2Affiliation,
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={"This day-long training will provide a deep dive on how to tailor DBT for Autistic clients."}
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      recordingWillBeAvailable={true}
    />
  );
};

export const BrookKrausMay2024 = () => {
  return (
    <DetailsPage
      notAWebinar="training"
      speakers={[
        {
          name: speaker1Name,
          image: <div>{speaker1Image}</div>,
          affiliation: speaker1Affiliation,
          about: (
            <div>
              Amara Brook, PhD, ABPP (they/them) is a licensed psychologist and Director at Flow Neurodiversity
              Affirming Psychological Services (flowps.com), a private practice serving clients in California, Nevada,
              Colorado, and Florida. They are also a Clinical Assistant Professor of Psychology in the Department of
              Psychiatry and Behavioral Sciences at the University of Nevada, Reno School of Medicine. Their passions
              are helping people understand themselves through psychological assessment and reach their goals using
              Dialectical Behavior Therapy (DBT) tailored to be optimally effective for their neurotype and other
              dimensions of diversity. They have particular interest in autism and ADHD. They frequently provide
              training and consultation to other clinicians around the world, helping them understand neurodivergent
              people and learn how to provide neurodiversity-affirming assessment and therapy. Recently, they were a
              guest on Charlie Swenson’s “To Hell and Back” DBT podcast discussing autism, neurodiversity, and ways of
              tailoring DBT to help autistic clients reach their life-worth living goals. Dr. Brook received their
              bachelor's degree from Harvard, PhD from the University of Michigan, and advanced clinical training from
              CSPP. They have completed numerous advanced trainings in Psychological Assessment, DBT, and CBT and are
              Board Certified in Behavioral & Cognitive Psychology through the American Board of Professional Psychology
              (ABPP). In the past, Dr. Brook served as Director of Postdoctoral Training, Supervisor, DBT Team
              Coordinator, and Embedded Psychologist in Emergency Medicine at Kaiser Santa Clara. They also provided
              clinical services at VA medical centers, and schools. They were also a psychology professor at Santa Clara
              University and taught a graduate-level DBT course there.
            </div>
          ),
        },
        {
          name: speaker2Name,
          image: <div>{speaker2Image}</div>,
          affiliation: speaker2Affiliation,
          about: (
            <div>
              Rachel Leah Kraus, LCSW-C (she/her) is licensed clinical social worker in private practice
              (www.krauspsychotherapy.com) serving clients virtually throughout the state of Maryland for therapy and
              throughout the United States for executive function coaching. She works from a DBT-informed approach and
              has a special interest in working with clients who have a borderline and/or OCD diagnosis on top of an
              Autistic, ADHD, or AuDHD neurotype utilizing a neurodiversity affirmative approach. Rachel received her
              bachelor's degree in Psychology (Ecopsychology) from Mount Holyoke College and her Master's in Social Work
              from the University of Maryland School of Social Work. She is a Social Work board approved supervisor
              through the State of Maryland. Rachel has participated in numerous advanced trainings in DBT, been
              providing DBT informed treatment since 2008 in various context including schools, residential treatment
              facilities, hospitals, and community mental health clinics before transitioning exclusively to private
              practice. She currently provides consultation to clinicians around the country and leads several
              consultation groups where she teaches clinicians skills on how to utilize the neurodiversity affirmative
              approach when interacting with clients.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      altCutOffHours={11}
      aboutSection={
        <div>
          Autistic people often struggle with emotion recognition and regulation, suicidality, and self-harm. In a
          society that often invalidates their experiences, Autistic people experience chronic traumatic invalidation
          and shame, and may respond with masking and camouflaging that is reinforced by societal norms and some
          treatments. Masking and camouflaging can further contribute to emotion dysregulation and elevated suicide
          risk. Guided by its biosocial theory and assumptions about clients and therapy, DBT is well-positioned to
          improve the lives of autistic individuals.
          <div style={{ marginBottom: "10px" }} />
          Recent research has suggested that DBT skills training can help Autistic people, and other research is
          exploring modifications that may increase accessibility, increase effectiveness, and prevent unintentional
          harm. The presenters combine lived experience of being Autistic with intensive training and experience
          providing DBT to Autistic clients. This day-long training witll provide a deep dive on how to tailor it for
          Autistic clients. Participants will learn hand-on skills and strategies on ways to modify DBT to optimally
          help Autistic clients move toward their life worth living goals.
        </div>
      }
      educationalObjectives={[
        "Explain the difference between the deficit-based model versus the neurodiversity model in understanding and serving Autistic clients",
        "Identify 2 things they can modify in their practice to reduce the impact of ableism",
        "Identify 2 specific ways to convey neuro-humility through their communication with clients",
        "State two traits of many autistic people that may call for modifications to standard DBT",
        "Articulate two general things to tailor to make their own practice more accessible for Autistic clients",
        "Articulate four specific ways that they can modify the DBT skills training modules in their practice to increase efficacy for autistic clients",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This training is intended for participants who have DBT training and are interested in applying this with Autistic clients",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      recordingWillBeAvailable={true}
      conflicts={[
        "Both authors are paid consultants on a research study (PI: Alana McVey, University of Washington) testing ways to improve effectiveness of DBT for Autistic clients.",
        "Both authors are also in contract with Guilford Press writing manual on tailoring DBT for Autistic clients, and will eventually receive royalties after this manual is published.",
      ]}
      suggestedReadings={suggestedReadings}
      scheduleLink="/schedule/BrookKraus-May2024"
    />
  );
};
