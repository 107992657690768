import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import TomcikImg from "src/assets/Tomcik.png";
import PortnerImg from "src/assets/Portner.jpeg";

const title = "RO-DBT for Disorders of Overcontrol";
const subtitle = "Introductory Training";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "May",
  date: "12th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 15,
  month: "May",
  date: "13th",
  year: 2022,
};

const creditAmount = 6;

const speaker1Image = (
  <img src={TomcikImg} alt={"Nathan Tomcik"} className={"heightBased"} />
);
const speaker2Image = (
  <img src={PortnerImg} alt={"Jimmy Portner"} className={"heightBased"} />
);

export const TomcikPortner = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Nathan Tomcik, Ph.D., ABPP",
          image: <div>{speaker1Image}</div>,
          affiliation: "Behavioral Health VA Central Ohio Healthcare System",
          about: (
            <div>
              Dr. Tomcik is the Chief Psychologist and Director of Outpatient
              Behavioral Health at the VA Central Ohio Healthcare System. He
              earned his doctorate in Clinical Psychology at the University of
              Tennessee and is board certified in Couple and Family Psychology.
              He was awarded the VA Secretary Hands and Heart Award for his work
              in promoting person centered mental health care for military
              veterans in Central Ohio and was awarded Public Sector
              Psychologist of the Year by the Ohio Psychological Association. He
              currently is a national VA trainer advancing the dissemination of
              evidence based psychotherapies including Integrative Behavioral
              Couple Therapy and Radically Open Dialectical Behavior Therapy.
            </div>
          ),
        },
        {
          name: "Jimmy Portner, LISW-S, LICDC-CS, BCD",
          image: <div>{speaker2Image}</div>,
          affiliation: "VA of Central Ohio Healthcare System",
          about: (
            <div>
              Jimmy Portner is the Intensive Treatment Division Director at the
              Columbus Ohio Veterans Administration. He specializes in the
              training, implementation and clinical supervision of the following
              Evidence Based Practices (EBP); Integrated Dual Diagnosis
              Treatment (IDDT), Assertive Community Treatment (ACT),
              Motivational Interviewing (MI), Motivational Enhancement Therapy
              (MET), and Dialectical Behavior Therapy (DBT). Jimmy is a
              certified introductory trainer for the emerging EBP, Radically
              Open-DBT. He has been practicing RO-DBT since 2014. Jimmy serves
              as an adjunct professor for the OSU College of Social Work where
              he received both his Bachelors and Master’s degrees in Social
              Work.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Self-control, the ability to inhibit competing urges, impulses, or
          behaviors is highly valued by most societies. However, excessive
          self-control has been linked to social isolation, aloof interpersonal
          functioning, maladaptive perfectionism, constricted emotional
          expressions, and difficult-to-treat mental health problems, such as
          anorexia nervosa, obsessive compulsive personality disorder and
          refractory depression. Based on 25 years of research, two NIMH funded
          randomized controlled trials (RCTs) with refractory depression, an
          open-trial with adult anorexia nervosa, and a large multi-site RCT
          (http://www.reframed.org.uk) the aim of this workshop is to introduce
          clinicians to the theoretical foundations and skills underlying
          Radically Open-Dialectical Behavior Therapy (RO-DBT) for disorders of
          overcontrol (Lynch, 2018 ).
          <br /> While resting on many of the core principles of standard DBT,
          the therapeutic strategies in RO-DBT are often substantially
          different. For example, RO-DBT contends that emotional loneliness
          represents the core problem for overcontrol, not emotion
          dysregulation. The biosocial theory for overcontrol posits that
          heightened threat sensitivity and diminished reward sensitivity
          transact with early family experiences emphasizing “mistakes as
          intolerable” and “self-control as imperative” to result in an
          overcontrolled coping style that limits opportunities to learn new
          skills and exploit positive social reinforcers. A novel thesis linking
          the communicative functions of emotional expression to the formation
          of close social bonds will be introduced, as well as new skills
          emphasizing receptivity, self-enquiry and flexible responding. New
          approaches designed to activate a neurobiological-based social-safety
          system, signal cooperation, encourage genuine self-disclosure,
          practice loving-kindness, and change unhelpful envy/bitterness will be
          introduced using slides, handouts, video clips, and role plays.
        </div>
      }
      educationalObjectives={[
        "Explain a new biosocial theory for OC",
        "Describe the RO-DBT treatment structure",
        "Describe new RO-DBT treatment strategies designed to enhance willingness for self-inquiry and flexible responding",
        "Describe the RO-DBT treatment hierarchy",
        "Describe a novel treatment mechanism positing open expression = trust = social connectedness",
        "List examples of strategies designed to improve pro-social cooperative signaling via activation of the parasympathetic nervous system’s social-safety system",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory",
        description:
          "This workshop is are interested in learning new approaches to disorders of overcontrol",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
