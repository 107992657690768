import React from "react";

export const ButtonGroup = ({
  children,
  style,
  className,
}: {
  children: React.ReactNode;
  style?: any;
  className?: string;
}) => {
  return (
    <div className={"ButtonGroup " + (className ?? "")} style={style}>
      {children}
    </div>
  );
};
