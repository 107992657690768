import React from "react";
import { InternalLink } from "./Common/InternalLink";
import APASponsorImg from "src/assets/apasponsor.jpg";

export const MainFooter = () => {
  return (
    <div className={"MainFooter"}>
      <div className="title">ABOUT EBPNE</div>
      <div className="mission">
        <div style={{ fontStyle: "italic" }}>
          Evidence-Based Practices of New England (EBPNE) is dedicated to the
          dissemination of psychological science for the benefit of
          psychologists and other mental health professionals.
        </div>
        <div style={{ fontWeight: 800 }}>
          EBPNE hosts continuing education workshops, conferences, and other
          learning opportunities that bring together psychological scientists
          and mental health clinicians with
        </div>
        <div className="missionObjective">
          Deepen understanding of, and build competencies in, the practice of
          evidence-based psychological treatments
        </div>
        <div className="missionObjective">
          Provide education and continuing consultation for clinicians in
          evidence-based practices to allow them to provide effective treatment
          across a wide-range of clients, most notably populations that
          historically have been underserved by psychological science
        </div>
        <div className="missionObjective">
          Enable psychologists and other mental health clinicians to build a
          community of learners and clinician-scientists dedicated to the
          intersection of the science and real-world practice of evidence-based
          treatments, with the goal of adding to the knowledge-base that fuels
          evidence-based practice.
        </div>
      </div>
      <div className="list">
        <div className="email">
          Reach out to us at{" "}
          <a href="mailto:info@ebpne.com" target="_blank">
            info@ebpne.com
          </a>{" "}
        </div>
        <div className="apa">
          Evidence-Based Practices of New England is approved by the American
          Psychological Association to sponsor continuing education for
          psychologists. Evidence-Based Practices of New England maintains
          responsibility for this program and its content.
          <img
            src={APASponsorImg}
            alt={"APA Approved"}
            style={{ marginLeft: "10px" }}
          />
        </div>
      </div>

      <div className="list">
        <div className={"item copyright"}>
          © {new Date().getFullYear()} Evidence-based Practices of New England,
          LLC. All Rights Reserved.
        </div>
        <div className="item">
          <InternalLink to={"/policies"}>Policies</InternalLink>
        </div>
      </div>
    </div>
  );
};
