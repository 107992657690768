import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import TomcikImg from "src/assets/Tomcik.png";

const title = "Integrative Behavioral Couples Therapy";
const subtitle =
  "A novel empirically based approach to helping couples in distress";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "October",
  date: "27th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "October",
  date: "27th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img src={TomcikImg} alt={"Nathan Tomcik"} className={"heightBased"} />
);

export const Tomcik = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Nathan Tomcik, Ph.D., ABPP",
          image: <div>{speakerImage}</div>,
          affiliation: "Behavioral Health VA Central Ohio Healthcare System",
          about: (
            <div>
              Dr. Tomcik is the Chief Psychologist and Director of Outpatient
              Behavioral Health at the VA Central Ohio Healthcare System. He
              earned his doctorate in Clinical Psychology at the University of
              Tennessee and is board certified in Couple and Family Psychology.
              He was awarded the VA Secretary Hands and Heart Award for his work
              in promoting person centered mental health care for military
              veterans in Central Ohio and was awarded Public Sector
              Psychologist of the Year by the Ohio Psychological Association. He
              currently is a national VA trainer advancing the dissemination of
              evidence based psychotherapies including Integrative Behavioral
              Couple Therapy and Radically Open Dialectical Behavior Therapy.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Integrative Behavior Couples Therapy (IBCT) is an empirically
          supported treatment that draws on both acceptance and change
          principles to help couples struggling with distress in their
          relationships. This webinar will provide an overview of the principles
          that guide IBCT and its theoretical underpinnings. Participants will
          learn central assessment methods to arrive at a case
          conceptualization, as well as treatment procedures that are utilized
          in this treatment. Background information on the research and support
          for IBCT will be discussed as well.
        </div>
      }
      educationalObjectives={[
        "Identify the central principles in evidence-based approaches to couple therapy",
        "Describe the theory behind Integrative Behavioral Couples Therapy (IBCT)",
        "Explain the evaluation and feedback procedures in IBCT",
        "List the core treatment procedures in IBCT",
        "Identify the empirical support for IBCT",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory",
        description:
          "This webinar is intended for those who are interested in learning new approaches to couples therapy.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
