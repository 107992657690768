import React from "react";
import ChrikiImg from "src/assets/Lyvia.jpg";
import { InternalLink } from "src/Components/Common/InternalLink";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const title = "Learning From History";
const subtitle =
  "What Can We Learn from Established Interventions When Thinking Through DBT Parent Guidance?";

const lowCost = {
  cost: 15,
  link: "https://buy.stripe.com/00g9CLfipejHaXu00J",
};
const highCost = {
  cost: 30,
  link: "https://buy.stripe.com/cN2g19dahb7vd5C5l4",
};

const startTime: UsefulDate = {
  time: 12,
  month: "September",
  date: "12th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "September",
  date: "12th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img
    src={ChrikiImg}
    alt={"Dr. Chriki"}
    className={"heightBased"}
    style={{ marginLeft: "-10px" }}
  />
);

export const ChrikiSept2022Session1Promo = () => {
  return (
    <HomeTalkPromo
      classname="partOfSeries"
      link={"ChrikiSept2022-Session1"}
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop will review relevant knowledge we have from established parent-based and family-based interventions to inform parent-baesd interventions within DBT."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const ChrikiSept2022Session1Details = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          This workshop will review established empirically-based parent-focused
          treatments as a way of informing therapists working with parents of
          adolescents and young adults within the DBT framework. Initial
          parent-based interventions primarily addressed issues of childhood
          non-compliance and aggression, and more recent interventions address
          childhood anxiety and OCD, as well as young adults struggling to
          launch. We will review these treatments and identify common themes to
          these interventions and effective strategies and data already
          established. We will, of course, discuss family-based interventions in
          DBT that already exist. We'll use these to help us think through
          principles that may be unique to DBT and pivotal to include in work
          with parents of emotionally dysregulated teens and young adults.
        </div>
      }
      educationalObjectives={[
        "Describe the two main components common to the evidence- parent-based interventions described in this workshop",
        "Discuss core principles and concepts from well-established parent-based interventions that could be applied in the context of DBT work with parents of teens and young adults",
        "Describe at least one specific intervention unique to DBT parent-based work",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory to Intermediate",
        description:
          "This workshop is intended for participants who have foundational training in DBT or general knowledge DBT and behavioral principles",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      notAWebinar={"workshop"}
    />
  );
};
