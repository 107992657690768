import React from "react";
import ChrikiImg from "src/assets/Lyvia.jpg";
import { InternalLink } from "src/Components/Common/InternalLink";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const subtitle =
  "A Workshop Series Drawing on the Data and Interventions We Know";

const lowCost = {
  cost: 50,
  link: "https://buy.stripe.com/dR64ir5HPdfDfdKdRx",
};
const highCost = {
  cost: 100,
  link: "https://buy.stripe.com/00gdT15HP6Rf3v214K",
};

const startTime: UsefulDate = {
  time: 12,
  month: "September",
  date: "12th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "October",
  date: "3rd",
  year: 2022,
};

const creditAmount = 6;

const speakerImage = (
  <img
    src={ChrikiImg}
    alt={"Dr. Chriki"}
    className={"heightBased"}
    style={{ marginLeft: "-10px" }}
  />
);

export const ChrikiSept2022Promo = () => {
  return (
    <HomeTalkPromo
      link={"ChrikiSept2022"}
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: <div />,
        },
      ]}
      title="Parent Guidance in DBT Series"
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This 4-part series helps clinicians gain a sense of direction and learn strategies to help bolster their work with parents of adolescents and young adults within the DBT model"
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const ChrikiSept2022Details = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title="Parent Guidance in DBT"
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Parent-based interventions can be dated back to the 1960s, a time when
          clinicians began to shift from identifying the struggling child as
          “the problem,” the only one in need of direct intervention, to the
          recognition that making changes to the environment in which the child
          exists could bring about a change in the child as well. This is
          especially relevant in the context of dialectical behavior therapy
          (DBT), where the invalidating environment is likely to reinforce
          emotion dysregulation and ineffective behaviors. In the last couple
          decades, family based interventions in DBT have flourished. In terms
          of parent-specific interventions, DBT for Children (DBT-C) was
          developed to help dysregulated youth by incorporating parent-based
          interventions to help shift the environment to a “change ready”
          environment by enhancing the skills in parents and caregivers. Many
          DBT programs have also begun focusing on interventions with
          parents/caregivers alone in the context of adolescents and young
          adults; however, there is less guidance on the strategies and
          structure of such an intervention. Especially since DBT for both
          adults and adolescents is intended for teens who are committed to
          treatment, identifying interventions that could help parents even when
          their child is not motivated for treatment could be especially
          important.
          <br />
          <br />
          This series of workshops is intended to help clinicians gain a sense
          of direction and learn strategies to help bolster their work
          specifically with parents of adolescents and young adults within the
          DBT model. The workshop will draw on the knowledge we have from DBT,
          as well as well-established evidence-based behavioral parent and
          family based interventions to help guide us in our work with parents
          in DBT.
          <br />
          <br />
          <div>
            <div style={{ fontWeight: "600" }}>
              {" "}
              Topics reviewed, among others:
            </div>
            <div className="objective">
              Overview of the history of parent-based behavioral interventions
            </div>
            <div className="objective">A broad overview of behaviorism</div>
            <div className="objective">
              Assessment and case conceptualization, chain analysis with parents
            </div>
            <div className="objective">
              Valued parenting – helping parents identify what kind of parents
              they strive to be
            </div>
            <div className="objective">
              “Control as the problem” in parenting -- helping parents accept
              and let go of control when needed
            </div>
            <div className="objective">
              Teaching and training parents in validation
            </div>
            <div className="objective">
              Setting expectations and effective limits, reducing accommodation
              and inadvertent reinforcement of avoidance and ineffective
              behaviors, as well as helping parents observe their own limits
            </div>
            <div className="objective">
              Teaching parents about dialectical dilemmas in chronic emotion
              dysregulation based on Linehan's model and helping them move
              towards reinforcement of more effective behaviors in their
              children using this conceptualization
            </div>
          </div>
          <br />
          <br />
          <div style={{ fontWeight: "600" }}>
            Schedule: <br />
            <div className="sessionList">
              <div className="session">
                <span>
                  <InternalLink to={"ChrikiSept2022-Session1"}>
                    Session 1:
                  </InternalLink>
                </span>{" "}
                Sept 12th 12-1:30{" "}
                <div>
                  <i>Introduction and Review of Established Interventions</i>
                </div>
              </div>
              <div className="session">
                <span>
                  <InternalLink to={"ChrikiSept2022-Session2"}>
                    Session 2:
                  </InternalLink>
                </span>{" "}
                Sept 19th 12-1:30{" "}
                <div>
                  <i>Case Conceptualization in DBT Parent Guidance</i>
                </div>
              </div>
              <div className="session">
                <span>Session 3:</span>{" "}
                <InternalLink to={"ChrikiSept2022-Session3-26th"}>
                  Sept 26th
                </InternalLink>{" "}
                -or-{" "}
                <InternalLink to={"ChrikiSept2022-Session3-28th"}>
                  Sept 28th
                </InternalLink>
                12-1:30 <i>(same talk given both days)</i>{" "}
                <div>
                  <i> Generating Goals and Commitment</i>
                </div>
              </div>
              <div className="session">
                <span>Session 4:</span> Oct 3rd 12-1:30{" "}
                <div>
                  <i>Validation and Limits</i>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div style={{ fontStyle: "italic" }}>
            Sign-ups will be available for individual workshops ($15 non-CE, $30
            CE). Currently we are signing-up for the full series ($50 non-CE
            option, $100 with CE credits). If you're uncertain if you want to
            attend all sessions, switching to the full set can be done any time
            before September 20th. A more detailed description of each session
            will be forthcoming.
          </div>
        </div>
      }
      educationalObjectives={[
        "Describe the two main components common to the evidence- parent-based interventions described in this workshop",
        "Discuss principles and concepts from well-established parent-based interventions that could be applied in the context of DBT work with parents of teens and young adults",
        "Describe at least one specific intervention unique to DBT parent-based work",
        "Describe strategies to use when guiding parents to define their goals for their work based on their values as parents",
        "Describe ways to help parents distinguish between their own goals as parents and their goals for their children",
        "Describe strategies to employ with parents when teaching them acceptance and validation skills",
        "Describe ways to help parents identify their own behaviors that may be inadvertently reinforcing ineffective behaviors in their children through parent chain analyses",
        "Describe contextual, including cultural, variables to take into account when conducting chain analyses with parents and conceptualizing cases",
        "Describe key components to behavior plans to construct with parents",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory to Intermediate",
        description:
          "This series of workshops is intended for participants who have foundational training in DBT or general knowledge DBT and behavioral principles",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      notAWebinar={"series"}
    />
  );
};
