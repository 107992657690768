import React from "react";
import { UsefulDate } from "src/utils";
import { ScheduleDisplay } from "./ScheduleDisplay";
import BrookImg from "src/assets/Brook.jpg";
import KrausImg from "src/assets/Kraus.jpeg";

const title = `Tailoring DBT to be more Effective and Affirming for Autistic Clients`;
const subtitle = "Full Day Training";

const lowCost = {
  cost: 90,
  link: "https://buy.stripe.com/5kAcOX2vD3F3c1y01a",
};
const highCost = {
  cost: 175,
  link: "https://buy.stripe.com/3cs2ajgmtfnLd5C8xH",
};

const startTime: UsefulDate = {
  time: 11,
  month: "May",
  date: "17th",
  year: 2024,
};
const endTime: UsefulDate = {
  time: 17,
  month: "May",
  date: "17th",
  year: 2024,
};

const speaker1Name = "Amara Brook, Ph.D., ABPP";
const speaker1Image = <img src={BrookImg} alt={"Dr. Brook"} />;
const speaker1Affiliation = "Flow Psychological Services, San Jose, CA					";

const speaker2Name = "Rachel Leah Kraus, LCSW-C";
const speaker2Image = <img src={KrausImg} alt={"Dr. Kraus"} />;
const speaker2Affiliation = "Kraus Psychotherapy, LLC";

const scheduleList = [
  {
    time: "11:00 AM",
    title: "Mindfulness",
  },
  {
    time: "11:15",
    title: "Autism as Neurodivergence",
  },
  { time: "12:00", title: "Why DBT May Help and Existing Evidence" },
  {
    time: "12:15",
    title: "SHORT BREAK",
  },
  {
    time: "12:30",
    title: "Recognizing Autistic Clients",
  },
  {
    time: "1:00",
    title:
      "Traits of Many Autistic Clients that Call for Tailoring Standard DBT",
  },
  { time: "1:45", title: "LONG BREAK" },
  {
    time: "2:15",
    title: "Mindfulness",
  },
  {
    time: "2:30",
    title: "General Principles of Tailoring and What to Tailor",
  },
  {
    time: "3:00",
    title: "Tailoring Pretreatment & Individual Therapy",
  },
  { time: "3:30", title: "SHORT BREAK" },
  {
    time: "3:45",
    title: "Tailoring Skills Training: Orientation",
  },
  {
    time: "3:45",
    title: "Tailoring Skills Training: Mindfulness",
  },
  {
    time: "3:45",
    title: "Tailoring Skills Training: Distress Tolerance",
  },
  {
    time: "3:45",
    title: "Tailoring Skills Training: Emotion Regulation",
  },
  {
    time: "3:45",
    title: "Tailoring Skills Training: Interpersonal Effectiveness",
  },
  { time: "5:00", title: "FIN" },
];

export const BrookKrausSchedule = () => {
  return (
    <ScheduleDisplay
      speakers={[
        {
          name: speaker1Name,
          image: <div>{speaker1Image}</div>,
          affiliation: speaker1Affiliation,
          about: (
            <div>
              Amara Brook, PhD, ABPP (they/them) is a licensed psychologist and
              Director at Flow Neurodiversity Affirming Psychological Services
              (flowps.com), a private practice serving clients in California,
              Nevada, Colorado, and Florida. Their passions are helping people
              understand themselves through psychological assessment and reach
              their goals using Dialectical Behavior Therapy (DBT) tailored to
              be optimally effective for their neurotype and other dimensions of
              diversity. They have particular interest in autism and ADHD. They
              frequently provide training and consultation to other clinicians
              around the world, helping them understand neurodivergent people
              and learn how to provide neurodiversity-affirming assessment and
              therapy. Recently, they were a guest on Charlie Swenson’s “To Hell
              and Back” DBT podcast discussing autism, neurodiversity, and ways
              of tailoring DBT to help autistic clients reach their life-worth
              living goals. Dr. Brook received their bachelor's degree from
              Harvard, PhD from the University of Michigan, and advanced
              clinical training from CSPP. They have completed numerous advanced
              trainings in Psychological Assessment, DBT, and CBT and are Board
              Certified in Behavioral & Cognitive Psychology through the
              American Board of Professional Psychology (ABPP). In the past, Dr.
              Brook served as Director of Postdoctoral Training, Supervisor, DBT
              Team Coordinator, and Embedded Psychologist in Emergency Medicine
              at Kaiser Santa Clara. They also provided clinical services at VA
              medical centers, and schools. They were also a psychology
              professor at Santa Clara University and taught a graduate-level
              DBT course there."
            </div>
          ),
        },
        {
          name: speaker2Name,
          image: <div>{speaker2Image}</div>,
          affiliation: speaker2Affiliation,
          about: (
            <div>
              Rachel Leah Kraus, LCSW-C (she/her) is licensed clinical social
              worker in private practice (www.krauspsychotherapy.com) serving
              clients virtually throughout the state of Maryland for therapy and
              throughout the United States for executive function coaching. She
              works from a DBT-informed approach and has a special interest in
              working with clients who have a borderline and/or OCD diagnosis on
              top of an Autistic, ADHD, or AuDHD neurotype utilizing a
              neurodiversity affirmative approach. Rachel received her
              bachelor's degree in Psychology (Ecopsychology) from Mount Holyoke
              College and her Master's in Social Work from the University of
              Maryland School of Social Work. She is a Social Work board
              approved supervisor through the State of Maryland. Rachel has
              participated in numerous advanced trainings in DBT, been providing
              DBT informed treatment since 2008 in various context including
              schools, residential treatment facilities, hospitals, and
              community mental health clinics before transitioning exclusively
              to private practice. She currently provides consultation to
              clinicians around the country and leads several consultation
              groups where she teaches clinicians skills on how to utilize the
              neurodiversity affirmative approach when interacting with clients.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      scheduleList={scheduleList}
      talkLink={"BrookKraus-May2024"}
      recordingWillBeAvailable={true}
    />
  );
};
