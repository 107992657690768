import React from "react";
import { Link } from "react-router-dom";
import { StyledButton } from "./StyledButton";

export let colorblindnessToggle = false;

export const ExternalLink = ({
  to,
  children,
  classname,
  color,
  noFade,
  unstyled,
  style,
}: {
  to: string;
  children: React.ReactNode;
  classname?: string;
  color?: "black" | "red" | "white";
  noFade?: boolean;
  unstyled?: boolean;
  style?: any;
}) => {
  return (
    <a
      href={to}
      className={`ExternalLink ${classname ?? ""} ${
        color ? `${color}Link` : ""
      } ${noFade ? "noFade" : ""} ${
        colorblindnessToggle ? " colorblindLinks" : ""
      }`}
      target="_blank"
      rel="noreferrer"
      style={style}
    >
      {children}
    </a>
  );
};
