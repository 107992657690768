import React from "react";

export const Section = ({
  title,
  children,
  subheading,
  headerImage,
  rightArea,
  classname,
  centerContent,
}: {
  title: string;
  children: React.ReactNode;
  subheading?: string;
  headerImage?: React.ReactNode;
  rightArea?: React.ReactNode;
  classname?: string;
  centerContent?: boolean;
}) => {
  return (
    <div
      className={`Section ${!!rightArea ? "withRightArea" : ""} ${
        classname ?? ""
      }`}
      style={centerContent ? { textAlign: "center" } : {}}
    >
      <div className={`heading ${!!headerImage ? "withHeaderImage" : ""}`}>
        {title}
      </div>
      <div className={`body ${!!headerImage ? "withHeaderImage" : ""}`}>
        {!!headerImage && <div className={"imageBuffer"}> </div>}
        {!!subheading && <div className="subheading">{subheading}</div>}{" "}
        {children}
      </div>
      {!!rightArea && <div className={"rightArea"}>{rightArea}</div>}
      {!!headerImage && (
        <div className={"headerImageHolder"}>{headerImage}</div>
      )}
    </div>
  );
};
