import React, { useEffect } from "react";
import { MainMenu } from "./MainMenu";
import { MainContent } from "./MainContent";
import { MainFooter } from "./MainFooter";
import { useLocation } from "react-router";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const TalksGroup = () => {
  const routerLocation = useLocation();

  return (
    <div
      className={`TalksGroup ${
        routerLocation.pathname === "/"
          ? "home"
          : routerLocation.pathname === "/about/"
          ? "about"
          : ""
      }`}
    >
      <MainMenu />

      <ScrollToTop />
      <MainContent />

      <MainFooter />
    </div>
  );
};
