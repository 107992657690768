import React from "react";

export const VideoWrapper = ({
  videoUrl,
  title,
  size,
  classname,
}: {
  videoUrl: string;
  title: string;
  size?: { x?: number; y?: number };
  classname?: string;
}) => {
  return (
    <div className={`VideoWrapper ${classname ?? ""}`}>
      <div className="videoTitle">{title}</div>
      <div className="videoAnchor">
        <iframe
          src={videoUrl}
          allowFullScreen={true}
          title={title}
          frameBorder={0}
        />
      </div>
    </div>
  );
};
