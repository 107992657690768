import React from "react";
import { VideoWrapper } from "../Common/VideoWrapper";

export const PublicTalks = () => {
  return (
    <>
      <VideoWrapper
        videoUrl="https://youtube.com/embed/2sQNksmXvBM"
        title="BIAS Model of BPD: Emotion Dysregulation as an Interpersonal Process with Dr. Fitzpatrick"
      />
      <VideoWrapper
        videoUrl="https://youtube.com/embed/FmTS-xvZnYw"
        title="Parent Guidance: 'Why is my child acting this way!?!'"
      />
      <VideoWrapper
        videoUrl="https://youtube.com/embed/hSCX2PFqYBg"
        title="Intro to CPT & PE"
      />
    </>
  );
};
