import React, { useState } from "react";
import { ButtonGroup } from "src/Components/Common/ButtonGroup";
import { ContinuingEdCreditTag } from "src/Components/Common/ContinuingEdCreditTag";
import { Modal } from "src/Components/Common/Modal";
import { DateTimeDisplayer } from "src/Components/Common/NEW/DateTimeDisplayer";
import { StyledButton } from "src/Components/Common/StyledButton";
import { UsefulDate, usefulDateIsPast } from "src/utils";

export type PurchaseOptions = {
  lowCost?: {
    cost: number | "FREE";
    link: string;
  };
  highCost: {
    cost: number | "FREE";
    link: string;
  };
};

export type Speaker = {
  name: string;
  extraTitling?: string;
  affiliation: string;
  image: React.ReactNode;
  about: React.ReactNode;
  smallImage?: boolean;
};

export type SchedulePageProps = {
  speakers: Speaker[];
  altLink?: string;
  start: UsefulDate;
  timeRange?: UsefulDate;
  end?: UsefulDate;
  title: string;
  subtitle?: string;
  purchaseOptions: PurchaseOptions;
  talkLink: string;
  scheduleList: {
    time: string;
    title: string;
  }[];
  recordingWillBeAvailable?: boolean;
};

export const ScheduleDisplay = ({
  speakers,
  altLink,
  start,
  timeRange,
  end,
  title,
  subtitle,
  purchaseOptions,
  talkLink,
  scheduleList,
  recordingWillBeAvailable,
}: SchedulePageProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openRegistrationModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className={"DetailsPage"}>
        <div className="buttonRow">
          <StyledButton internalLink="/" classname="clear">
            GO BACK
          </StyledButton>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {!usefulDateIsPast(start, 1) ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {recordingWillBeAvailable === false && (
                  <span
                    style={{
                      fontWeight: 500,
                      marginRight: "20px",
                      color: "white",
                    }}
                    className={"notOnPhone"}
                  >
                    No recordings will be made available
                  </span>
                )}
                {!!altLink ? (
                  <StyledButton externalLink={altLink} classname="pink">
                    LEARN MORE & REGISTER (External Site)
                  </StyledButton>
                ) : (
                  <StyledButton
                    onClick={openRegistrationModal}
                    classname="pink"
                  >
                    REGISTER NOW!
                  </StyledButton>
                )}
              </div>
            ) : end !== undefined && !usefulDateIsPast(end) ? (
              <div>Happening Now!</div>
            ) : (
              <div>
                <StyledButton
                  externalLink="https://forms.gle/A8GUQsYRsEKidrzG8"
                  classname="pink"
                >
                  Sign-Up For Future Info
                </StyledButton>
              </div>
            )}
          </div>
        </div>
        {recordingWillBeAvailable === false && (
          <span
            style={{ fontWeight: 500, color: "white" }}
            className={"phoneOnly"}
          >
            No recordings will be made available
          </span>
        )}
        <div className="detailsBody">
          <div />
          <div className="headerInfo">
            <DateTimeDisplayer
              date={start}
              secondDate={end}
              scheduleLink={`/talks/${talkLink}`}
            />
          </div>
          <div className="speakersArea">
            {speakers.map((speaker) => (
              <div key={speaker.name} className="individualSpeaker">
                <div className="speakerImage">{speaker.image}</div>
                <div className="speakerName">{speaker.name}</div>
                <div className="speakerExtraTitles">{speaker.extraTitling}</div>
                <div className="speakerAffiliation">{speaker.affiliation}</div>
              </div>
            ))}
          </div>
          <div className="scheduleArea">
            <div className="talkTitle">{title}</div>
            {!!subtitle && <div className="talkSubtitle">{subtitle}</div>}

            <div className="schedule">
              <div className="scheduleTitle">
                Schedule <span>All times are EST</span>
              </div>
              {scheduleList.map((item) => {
                return (
                  <div
                    className={
                      "scheduleInstance " +
                      (item.title === "BREAK" ||
                      item.title === "SHORT BREAK" ||
                      item.title === "LONG BREAK" ||
                      item.title === "FIN"
                        ? "break "
                        : "")
                    }
                  >
                    <div className="scheduleInstanceTime">{item.time}</div>
                    <div className="scheduleInstanceInfo">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} title={"Registration"}>
          <div
            style={{
              marginBottom: "40px",
              padding: "20px",
              background: "#f9faf4",
            }}
          >
            In order to be awarded APA Continuing Education (CE) credit,
            attendees are required to do the following:
            <ul>
              <li>
                Attend the event in its entirety. No credit will be given for
                partial attendance.
              </li>
              <li> Complete an evaluation form of the event.</li>
            </ul>
          </div>
          <ButtonGroup className="right">
            {!!altLink ? (
              <StyledButton externalLink={altLink} classname="pink">
                LEARN MORE & REGISTER (External Site)
              </StyledButton>
            ) : (
              <>
                <StyledButton
                  addOn={`${
                    purchaseOptions.highCost.cost === "FREE" ? "" : "$"
                  }${purchaseOptions.highCost.cost}`}
                  externalLink={purchaseOptions.highCost.link}
                  classname="pink"
                >
                  Register (APA CE)
                </StyledButton>
                {purchaseOptions.lowCost && (
                  <StyledButton
                    addOn={`${
                      purchaseOptions.lowCost.cost === "FREE" ? "" : "$"
                    }${purchaseOptions.lowCost.cost}`}
                    externalLink={purchaseOptions.lowCost.link}
                    classname="pink"
                  >
                    Register
                  </StyledButton>
                )}
              </>
            )}
          </ButtonGroup>
        </Modal>
      )}
    </>
  );
};
