import React from "react";
import { ConnectingLink } from "./ConnectingLink";

export const About = () => {
  return (
    <div className={"About"}>
      <div className="title">Who are we?</div>
      Evidence Based Practices of New England is a passion project started by
      psychologists who have always been eager for more learning in the field.
      Our goal is to promote training in evidence based treatments for mental
      health clinicians, with a particular focus on addressing the complexities
      that arise within the treatments that exist.
      <br />
      <br />
      Over the last five decades, evidence based treatments have developed and
      evolved. Today, even issues that were once thought as very difficult to
      treat, such as Borderline Personality Disorder, Substance Use Disorders,
      OCD, and Psychosis, to name a few, have effective treatments grounded in
      science and research, that help individuals find relief from suffering and
      build lives of meaning.
      <br />
      <br />
      As complex as individuals can be, so too need to be the techniques and
      approaches aimed at helping them. We hope you will join us in our mission
      to cultivate a community of clinicians continuously striving to expand
      their knowledge and build upon their competence.
      <br />
      <br />
      If you're interested in teaching or have good data-driven talks you'd like
      to deliver, <ConnectingLink altBody="let us hear about it!" />
    </div>
  );
};
