import React, { Children } from "react";
import { InternalLink } from "./InternalLink";

export const StyledButton = ({
  children,
  classname,
  hover,
  onClick,
  addOn,
  internalLink,
  externalLink,
  style,
}: {
  children: React.ReactNode;
  classname?: string;
  hover?: string;
  onClick?: React.MouseEventHandler;
  addOn?: React.ReactNode;
  internalLink?: string;
  externalLink?: string;
  style?: any;
}) => {
  const renderButton = (
    <div onClick={onClick} title={hover}>
      <div>{children}</div>
      {!!addOn && <div>{addOn}</div>}
    </div>
  );

  return !!internalLink ? (
    <InternalLink
      classname={`StyledButton simple ${classname ?? ""} ${
        !!addOn ? "twoPart" : ""
      }`}
      to={internalLink}
      style={style}
    >
      {renderButton}
    </InternalLink>
  ) : !!externalLink ? (
    <a
      href={externalLink}
      className={`StyledButton ${classname ?? ""} ${!!addOn ? "twoPart" : ""}`}
      target="_blank"
      rel="noreferrer"
      style={style}
    >
      {renderButton}
    </a>
  ) : (
    <div
      className={`StyledButton ${classname ?? ""} ${!!addOn ? "twoPart" : ""}`}
      style={style}
    >
      {renderButton}
    </div>
  );
};
