import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import ConleyImg from "src/assets/Conley.png";

const title = "“I don’t think you actually have a client yet!”";
const subtitle = "Pre-treatment and commitment in DBT";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "January",
  date: "24th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 15,
  month: "January",
  date: "24th",
  year: 2022,
};

const creditAmount = 3;

const speakerImage = (
  <img src={ConleyImg} alt={"Christopher Conley"} className="heightBased" />
);

export const Conley = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Christopher Conley, M.S.W., RSW",
          image: <div>{speakerImage}</div>,
          affiliation: "McMaster University",
          about: (
            <div>
              Christopher Conley is an Assistant Clinical Professor (PT) in the
              Department of Psychiatry and Behavioral Neurosciences at McMaster
              University. He is a clinical social worker and has over 18 years
              of experience working in a wide range of services including child
              welfare, emergency psychiatry, outpatient mental health, juvenile
              justice, and in implementing evidence-based treatments in
              community services. He is experienced in a number of behavioral
              and cognitive behavioral treatments including Prolonged Exposure
              (PE) for PTSD, Exposure and Response Prevention (ERP) for OCD, and
              CBT treatments for mood and anxiety disorders. He is an approved
              PE consultant and supervisor with Dr. Edna Foa and is certified
              through the DBT Linehan Board of Certification as a Certified
              Clinician™. He has been providing comprehensive DBT treatment
              since 2005 with adolescents and adults. Currently, Christopher
              volunteers for the DBT Linehan Board of Certification as an
              adherence coder. He has led many DBT trainings for teams and
              individuals and is actively involved in funded research studies.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Dialectical Behavior Therapy (DBT) is a treatment that requires a lot
          of resources from both the therapist and client. Clients arriving at
          the door of a DBT therapist are at times desperate and so are their
          families! Many clients seeking DBT have been through multiple
          treatments some of which have been ineffective or iatrogenic. Missteps
          in pre-treatment and assessment for therapists who are eager to get
          started can generate avoidable problems. Marsha Linehan’s approach to
          treatment at the time was unique with the creation of a distinct
          pre-treatment section of the therapy with which to explain and
          demystify the treatment, establish client goals, conduct further
          assessment, and collaborate on the targets and problems for treatment.
          Without this DBT pre-treatment stage, you can set sail in therapy for
          destinations unknown - which contributes to therapist and client
          frustration alike. It can be confusing at times to consider whether to
          use strategies to extinguish, assess, go back to commitment and
          agreement, or whether to consider using aversive contingencies (with
          care). context of the DBT Prolonged Exposure (DBT PE) protocol for
          PTSD.
        </div>
      }
      educationalObjectives={[
        "Describe the tasks in the four pre-treatment commitment sessions ",
        "Identify specific commitment strategies to use with patients in this pre-treatment phase",
        "Identify effective ways to respond to patients who are struggling to maintain their commitment during DBT",
        "Use pre-treatment information to inform your DBT case conceptualization",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate to Advanced",
        description:
          "This webinar is intended for those who have had at least moderate experience practicing DBT.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
