import React from "react";
import { UsefulDate } from "src/utils";
import { ScheduleDisplay } from "./ScheduleDisplay";
import ChapmanImg from "src/assets/Chapman-Alex.jpg";

const title = `Phone Coaching in DBT`;
const subtitle = "Navigating Things Effectively";

const lowCost = {
  cost: 90,
  link: "https://buy.stripe.com/00g3en2vD1wV4z6dRL",
};
const highCost = {
  cost: 175,
  link: "https://buy.stripe.com/7sI16fc6d5Nb3v24ha",
};

const startTime: UsefulDate = {
  time: 11,
  month: "April",
  date: "24th",
  year: 2023,
};
const endTime: UsefulDate = {
  time: 17.5,
  month: "April",
  date: "24th",
  year: 2023,
};

const speakerName = "Alexander L. Chapman, Ph.D., R.Psych";
const speakerImage = <img src={ChapmanImg} alt={"Dr. Chapman"} />;
const speakerAffiliation =
  "Simon Fraser University & DBT Centre of Vancouver, Inc.";

const scheduleList = [
  {
    time: "11:00 AM",
    title: "Introduction, orientation, and mindfulness practice",
  },
  {
    time: "11:30",
    title: "Phone coaching in DBT, principles, structure, and strategies",
  },
  { time: "12:30 PM", title: "BREAK" },
  {
    time: "12:45",
    title: "Phone coaching in DBT, continued; making it sustainable",
  },
  { time: "2:00", title: "BREAK" },
  {
    time: "2:30",
    title: "Principles for the assessment and management of suicide risk",
  },
  { time: "4:00", title: "BREAK" },
  { time: "4:15", title: "Navigating challenging phone coaching calls" },
  { time: "5:30", title: "FIN" },
];

export const ChapmanSchedule = () => {
  return (
    <ScheduleDisplay
      altLink={
        "https://app.ce-go.com/phone-coaching-in-dbt-navigating-things-effectively"
      }
      speakers={[
        {
          name: speakerName,
          image: <div>{speakerImage}</div>,
          affiliation: speakerAffiliation,
          about: (
            <div>
              Alexander L. Chapman, PhD, RPsych, is Professor and Clinical
              Science Area Coordinator in the Department of Psychology at Simon
              Fraser University (SFU), in Burnaby, British Columbia, Canada, and
              president of the Dialectical Behavior Therapy (DBT) Centre of
              Vancouver. Dr. Chapman directs the Personality and Emotion
              Research Laboratory at SFU, where he studies the role of emotion
              regulation in borderline personality disorder, self-harm,
              impulsivity, and other behavioral problems. He has published
              numerous scientific articles chapters, and books; presents widely
              at scientific conferences; and is on the editorial boards of
              Personality Disorder: Theory, Research, and Treatment, Journal of
              Personality Disorders, and Behavior Therapy. Dr. Chapman is a DBT
              Trainer and Consultant with Behavioral Tech. He regularly gives
              local, national, and international workshops and invited talks on
              DBT and the treatment of borderline personality disorder; has
              consulted with and trained clinicians in Canada, the United
              States, and the United Kingdom; and trains and supervises clinical
              psychology students. He is a recipient of awards including the
              Young Investigator’s Award from the National Education Alliance
              for Borderline Personality Disorder, the Early Career Scientist
              Practitioner Award from the Canadian Psychological Association,
              and an 8-year Career Investigator Award from the Michael Smith
              Foundation for Health Research.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      scheduleList={scheduleList}
      talkLink={"Chapman"}
      recordingWillBeAvailable={false}
    />
  );
};
