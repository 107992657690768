import React from "react";

export const Policies = () => {
  return (
    <div className={"Policies"}>
      <div className={"areaTitle"}>Disability Support</div>
      <div className={"content"}>
        ADA accommodations will be made in accordance with the law; please
        indicate your needs at the time of registration.
        <br />
        We try to make all the webinars as accessible as possible, but sometimes
        we miss something. Please let us know during registration so we can
        prepare.
      </div>
      <div className={"areaTitle"}>Grievance Policies</div>
      <div className={"content"}>
        Evidence-Based Practices of New England (EBPNE) is fully committed to
        conducting all activities in strict conformance with the American
        Psychological Association's Ethical Principles of Psychologists. EBPNE
        will comply with all legal and ethical responsibilities to be
        non-discriminatory in promotional activities, program content and in the
        treatment of program participants. The monitoring and assessment of
        compliance with these standards will be the responsibility of Dr. Lyvia
        Chriki in consultation with the members of the continuing education
        committee.
        <br />
        <br /> While EBPNE goes to great lengths to assure fair treatment for
        all participants and attempts to anticipate problems, there will be
        occasional issues that come to the attention of the convention staff
        which require intervention and/or action on the part of the convention
        staff or an officer of EBPNE. This procedural description serves as a
        guideline for handling such grievances.
        <br />
        <br />
        <br />
        If you wish to file a grievance, please note that the following actions
        will be taken:
        <br />
        <br /> 1. If the grievance concerns a speaker, the content presented by
        the speaker, or the style of presentation, please put your comments in
        written format and provide them to Dr. Chriki. Dr. Chriki will make sure
        the speaker gets the comments, assuring your confidentiality. <br />
        <br /> 2. If the grievance concerns a workshop offering, its content,
        level of presentation, or the facilities in which the workshop was
        offered, the convention chair will mediate and will be the final
        arbitrator. If you request action, Dr. Chriki will:
        <br /> a. attempt to move you to another workshop, or
        <br /> b. provide a credit for a subsequent year's workshop, or <br />
        c. provide a partial or full refund of the workshop fee. <br />
        <br />
        Actions 2b and 2c will require a written note, documenting the
        grievance, for record keeping purposes. The note need not be signed by
        you. <br />
        <br />
        3. If the grievance concerns the EBPNE CE program, in a specific regard,
        Dr. Chriki will attempt to arbitrate.
        <br />
        <br />
        <br /> To contact Dr. Chriki, please call (617) 286-4919. Correspondence
        can be sent to EBPNE, 1280 Center Street, Suite 210A, Newton Center, MA
        02459.
      </div>
      <div className={"areaTitle"}>Refunds</div>
      <div className={"content"}>
        <div className={"subTitle"}>Webinars</div>
        No refunds are available for our webinars (1 to 1.5 hours long). If you
        paid for registration, and can not attend for any reason whatsoever, you
        will be given access to a recording, if a recording was made. Please
        note that some presenters do not permit recording and no recordings can
        be guaranteed. If you have difficulty viewing the live webinar because
        of any technical aspects on your end, no refunds will be made available.
        Under that circumstance you would be given access to a recording of the
        webinar if there is a recording.
        <br />
        <br />
        <div className={"subTitle"}>Workshops/Trainings</div>
        These are sessions 3 hours or longer. If you paid for registration, and
        can not attend for any reason whatsoever, you will be refunded as long
        as you notify us 10 days prior to the training. The refund will be in
        full amount after the 3% processing fee.
        <br />
        <br />
      </div>
      <div className={"areaTitle"}>Conflicts of Interest</div>
      <div className={"content"}>
        In the rare exception where there is a conflict of interest for a
        webinar, we will make it clear on the page for that webinar in plain
        language. Absence of that declaration is signifying that we have no
        known conflict of interest.
      </div>
    </div>
  );
};
