import React from "react";
import { CatalogLanding } from "./Features/Catalog/CatalogLanding";
import { ConnectingLanding } from "./Features/Connecting/ConnectingLanding";
import { About } from "./Features/Connecting/About";
import { Switch, Route, useLocation } from "react-router-dom";
import { FourOhFour } from "./FourOhFour";
import { HomePage } from "./Features/Home/HomePage";
import { Policies } from "./Features/Extra/Policies";
import { TalkDescription } from "./Features/Talks/TalkDescription";
import { PreviousTalks } from "./Features/PreviousTalks";
import { ScheduleRouter } from "./Features/Schedules/ScheduleRouter";

export const MainContent = () => {
  const routerLocation = useLocation();

  return (
    <>
      <div className={"MainContent"}>
        <div className={"content"}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/connect/">
              <ConnectingLanding />
            </Route>
            <Route path="/catalog/">
              <CatalogLanding />
            </Route>
            <Route path="/about/">
              <About />
            </Route>
            <Route path="/policies/">
              <Policies />
            </Route>
            <Route path="/talks/:routeTalkId/:routeTalkDetails/">
              <TalkDescription />
            </Route>
            <Route path="/talks/:routeTalkId/">
              <TalkDescription />
            </Route>
            <Route path="/talks/">
              <TalkDescription />
            </Route>
            <Route path="/schedule/:scheduleId">
              <ScheduleRouter />
            </Route>

            <Route path="*">
              <FourOhFour />
            </Route>
          </Switch>
        </div>
      </div>
      {routerLocation.pathname === "/" && <PreviousTalks />}
    </>
  );
};
