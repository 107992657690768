import React from "react";
import MorrisonImg from "src/assets/Morrison.jpg";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const title = `Going Beyond The Pain`;
const subtitle =
  "Pain Neuroscience Education as a Strategy for Helping Patients with Chronic Pain";

const lowCost = {
  cost: 25,
  link: "https://buy.stripe.com/dR65mvgmtgrP5DadRM",
};
const highCost = {
  cost: 45,
  link: "https://buy.stripe.com/7sI16f0nv1wV6HeeVR",
};

const startTime: UsefulDate = {
  time: 12,
  month: "March",
  date: "3rd",
  year: 2023,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "March",
  date: "3rd",
  year: 2023,
};

const creditAmount = 1.5;

const speakerImage = (
  <img src={MorrisonImg} alt={"Eleshia Morrison, Ph.D., L.P., ABPP"} />
);

export const MorrisonPromo = () => {
  return (
    <HomeTalkPromo
      link={"Morrison"}
      speakers={[
        {
          name: "Eleshia J.P. Morrison, Ph.D., L.P., ABPP",
          image: <div>{speakerImage}</div>,
          affiliation: "Mayo Clinic College of Medicine and Science",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This webinar will introduce basics of using PNE to help individuals with chronic pain."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const MorrisonDetails = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Eleshia J.P. Morrison, Ph.D., L.P., ABPP",
          extraTitling: "Assistant Professor of Psychology",
          image: <div>{speakerImage}</div>,

          affiliation: "Mayo Clinic College of Medicine and Science",
          about: (
            <div>
              Dr. Eleshia (E-lee-she-ah) J.P. Morrison, Ph.D., L.P., ABPP is a
              clinical health psychologist at Mayo Clinic in Rochester,
              Minnesota in the Adult Pain Rehabilitation Center (PRC) program.
              Her expertise is in chronic pain, transplant, and oncology. A
              native of Toronto, Canada, she has been with Mayo Clinic since
              2013. She is board certified with the American Board of
              Professional Psychology in Clinical Health Psychology. Dr.
              Morrison’s research interests include psychiatric and
              psychological correlates of medical illness, young adult
              functioning, sexual health, and equity/diversity factors in
              health. Dr. Morrison is a research scholar with the Mayo Clinic
              Kern Center for the Science of Health Care Delivery, where her
              research is focused on dissemination of a digital health
              behavioral intervention for racially diverse and sex & gender
              diverse patients living with chronic pain. She has received awards
              and recognition for her involvement in health equity/diversity
              initiatives, and for her research. Dr. Morrison holds various
              roles in education, including Director of Education for the PRC
              and Course Director for the Pain & Opioid curriculum with Mayo’s
              Alix School of Medicine. She is the former Director of the
              Clinical Health Psychology Postdoctoral Fellowship and former
              Course Director for the Department of Psychiatry & Psychology’s
              workshop on diversity and cultural humility.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Many clinicians are faced with patients struggling with chronic pain,
          whether or not this is their area of specialty. Often, these patients
          report that their medical team has ruled out clear physiological
          reasons for their experience of pain. In many cases, the absence of a
          biomedical cause leads to the inaccurate implication that pain is
          caused by psychiatric factors. This faulty assumption leaves patients
          feeling misunderstood and invalidated, which enhances distress,
          exacerbates pain behaviors, and amplifies functional debility.
          Patients function better when they are given pain neuroscience
          education (PNE) to explain the hypersensitivity of the protective pain
          neurocircuit, which is not detected through objective medical tests.
          PNE provides a basis for introducing how evidence-based behavioral
          approaches help to retrain the brain to dampen pain neurocircuitry
          activity, and help patients change their relationship to their pain.
          <br />
          <br />
          Most patients with chronic pain will be treated by non-pain
          specialists. PNE is an approach that can be applied by any clinician
          to support patients in reducing their suffering and enhancing function
          in spite of their pain. This webinar will present PNE and behavioral
          intervention as evidence based approaches to treating chronic pain. We
          will discuss the psychological/psychiatric correlates of chronic pain
          and use case examples, stories, and metaphors to demonstrate how PNE
          is applied with patients. Finally, we will discuss the health
          inequities that exist in chronic pain care that providers should be
          aware of.
        </div>
      }
      educationalObjectives={[
        "Identify important concepts related to Pain Neuroscience Education",
        "Explain the biopsychosocial model of pain",
        "Describe the psychological/psychiatric and sociocultural correlates of chronic pain",
        "Identify evidence-based practical approaches for behavioral management of chronic pain",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory",
        description:
          "This webinar is intended for clinician who have little to no background in Pain Neuroscience Education.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
