import React from "react";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import ChrikiImg from "src/assets/Lyvia.jpg";

const title = `Dialectical Parent Guidance`;
const subtitle = "DBT-Based Parent Guidance with Parents of Teens and Young Adults";

const lowCost = {
  cost: 45,
  link: "https://buy.stripe.com/6oEeX59Y5dfDc1yg0c",
};
const highCost = {
  cost: 75,
  link: "https://buy.stripe.com/dR6bKT5HPgrP5Da3dr",
};

const startTime: UsefulDate = {
  time: 12,
  month: "August",
  date: "26th",
  year: 2024,
};
const endTime: UsefulDate = {
  time: 15,
  month: "August",
  date: "26th",
  year: 2024,
};

const creditAmount = 3;

const speaker1Name = "Lyvia Chriki, Ph.D.";
const speaker1Image = (
  <img src={ChrikiImg} alt={"Dr. Chriki"} className={"heightBased"} style={{ marginLeft: "-10px" }} />
);
const speaker1Affiliation = "Boston Evidence Supported Treatments & EBPNE";

export const ChrikiAugust2024Promo = () => {
  return (
    <HomeTalkPromo
      link={"Chriki-Aug2024"}
      speakers={[
        {
          name: speaker1Name,
          image: <div>{speaker1Image}</div>,
          affiliation: speaker1Affiliation,
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop is intended to help clinicians learn to apply dialectical principles to thoughtfully engage in parent guidance work with parents of teens and young adults within the DBT model."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      recordingWillBeAvailable={true}
    />
  );
};

export const ChrikiAug2024 = () => {
  return (
    <DetailsPage
      notAWebinar="workshop"
      speakers={[
        {
          name: speaker1Name,
          image: <div>{speaker1Image}</div>,
          affiliation: speaker1Affiliation,
          about: (
            <div>
              "Dr. Chriki is the founder of Boston Evidence Supported Treatments & Practices (BEST Practices) and
              Evidence Based Practices of New England (EBPNE). She specializes in providing Dialectical Behavior Therapy
              (DBT), treatment for trauma and PTSD, and parent guidance & consultation. Dr. Chriki received her
              doctorate degree from the Ohio State University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the board of the New England Personality
              Disorder Association (NEPDA). "
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      altCutOffHours={5}
      aboutSection={
        <div>
          In the last couple decades, family based interventions in DBT have flourished. While programs have begun
          focusing on interventions with parents/caregivers of adolescents and young adults within DBT, more guidance is
          needed on the strategies and structure of such interventions. Research and practice in parent-based
          interventions with other populations suggests intervening with parents has great potential to help teens and
          young adults, not just younger children. This is particularly pertinent to us as DBT providers - Since DBT for
          both adults and adolescents is intended for those who are committed to treatment, identifying interventions
          that could help parents even when their child is not motivated for treatment could be especially important.
          <div style={{ marginBottom: "10px" }} />
          Drawing on principles from existing evidence-based behavioral parenting interventions and concepts from DBT,
          this workshop is intended to help clinicians feel more grounded in theoretical foundations of parenting work.
          Participants will learn hands-on strategies on how to help parents identify ways they may inadvertently
          reinforce emotion dysregulation in their teens and young adult children, engage parents in commitment
          strategies, and teach parents to engage more effectively with their children. Participants will learn to apply
          dialectical principles to thoughtfully engage in parent guidance with parents of teens and young adults within
          the DBT model.
        </div>
      }
      educationalObjectives={[
        "Describe 2 concepts from existing behavioral evidence based treatments they can apply within the DBT model",
        "Identify at least one dialectic to keep in mind when conceptualizing parent guidance work within DBT",
        "Identify one way parents may inadvertently reinforce emotion dysregulation through the lens of dialectical dilemmas",
        "Describe at least one strategy to use with parents when engaging in DBT parent guidance work",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description: "This workshop is appropriate for clinicians wtih foundational training in DBT",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      recordingWillBeAvailable={true}
    />
  );
};
