import React, { useState } from "react";
import { ButtonGroup } from "src/Components/Common/ButtonGroup";
import { ContinuingEdCreditTag } from "src/Components/Common/ContinuingEdCreditTag";
import { Modal } from "src/Components/Common/Modal";
import { DateTimeDisplayer } from "src/Components/Common/NEW/DateTimeDisplayer";
import {
  MissedRecordingPrompt,
  PurchaseOptions,
  PurchaseRecordingPrompt,
  Speaker,
  WatchRecordingPrompt,
} from "src/Components/Common/NEW/DetailsPage";
import { StyledButton } from "src/Components/Common/StyledButton";
import { UsefulDate, usefulDateIsPast } from "src/utils";

export type DetailsPageProps = {
  link: string;
  altLink?: string;
  speakers: Speaker[];
  start: UsefulDate;
  end?: UsefulDate;
  title: string;
  subtitle?: string;
  miniAbout: string;
  numberOfCredits: number;
  purchaseOptions: PurchaseOptions;
  recordingWillBeAvailable?: boolean;
  recordingIsPurchasable?: boolean;
  videoLink?: string;
  classname?: string;
};

export const HomeTalkPromo = ({
  link,
  altLink,
  speakers,
  start,
  end,
  title,
  subtitle,
  miniAbout,
  numberOfCredits,
  purchaseOptions,
  recordingWillBeAvailable,
  recordingIsPurchasable,
  videoLink,
  classname,
}: DetailsPageProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openRegistrationModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className={"HomeTalkPromo " + (classname ?? "")}>
        <div className="detailsBody">
          <div />
          <div className="speakersArea">
            {speakers.map((speaker) => (
              <div key={speaker.name} className="individualSpeaker">
                <div>
                  <div
                    className={`speakerImage ${
                      !!speaker.smallImage ? "small" : ""
                    }`}
                  >
                    {speaker.image}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex1">
            <div className="headerInfo">
              <DateTimeDisplayer date={start} secondDate={end} />
              <ContinuingEdCreditTag numberOfCredits={numberOfCredits} />
            </div>

            <div className="aboutArea">
              <div className="talkTitle" style={{ display: "block" }}>
                <div
                  style={{
                    width: "80%",
                    marginBottom: "10px",
                    lineHeight: "28px",
                  }}
                >
                  {title}
                </div>{" "}
                <span style={{ display: "block", marginBottom: "10px" }}>
                  with
                </span>{" "}
                <div
                  className="speakerName"
                  style={{ marginLeft: "0", lineHeight: "24px" }}
                >
                  {speakers.map((speaker, ind) => (
                    <div key={speaker.name + ind}>
                      {speaker.name}{" "}
                      {ind !== speakers.length - 1 && <span>&</span>}
                    </div>
                  ))}
                </div>
              </div>
              {!!subtitle && <div className="talkSubtitle">{subtitle}</div>}
              <div className="aboutTheTalk">
                <div>{miniAbout}</div>
              </div>
            </div>

            <div className="buttonRow">
              {!!altLink ? (
                <StyledButton externalLink={altLink} classname="pink">
                  LEARN MORE & REGISTER (External Site)
                </StyledButton>
              ) : (
                <StyledButton internalLink={`talks/${link}`} classname="clear">
                  LEARN MORE
                </StyledButton>
              )}

              {!!altLink ? null : videoLink ? (
                <WatchRecordingPrompt link={videoLink} />
              ) : !usefulDateIsPast(start, 3) ? (
                <>
                  {recordingWillBeAvailable === false && (
                    <span style={{ fontWeight: 500 }} className={"desktopOnly"}>
                      No recordings will be made available
                    </span>
                  )}
                  <StyledButton
                    onClick={openRegistrationModal}
                    classname="pink"
                  >
                    REGISTER NOW!
                  </StyledButton>
                </>
              ) : usefulDateIsPast(start, 3) && !usefulDateIsPast(start) ? (
                <StyledButton classname="disabled">
                  Registration Closed
                </StyledButton>
              ) : recordingWillBeAvailable ? (
                <MissedRecordingPrompt />
              ) : recordingIsPurchasable ? (
                <PurchaseRecordingPrompt />
              ) : end !== undefined && !usefulDateIsPast(end) ? (
                <div>Happening Now!</div>
              ) : (
                <div></div>
              )}
            </div>
            {recordingWillBeAvailable === false && (
              <span
                style={{
                  fontWeight: 500,
                  marginTop: "8px",
                  textAlign: "right",
                }}
                className={"notOnDesktop"}
              >
                No recordings will be made available
              </span>
            )}
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} title={"Registration"}>
          <div style={{}}>
            In order to be awarded APA Continuing Education (CE) credit,
            attendees are required to do the following:
            <ul>
              <li>
                Attend the event in its entirety. No credit will be given for
                partial attendance.
              </li>
              <li> Complete an evaluation form of the event.</li>
            </ul>
          </div>
          <ButtonGroup className="right">
            <StyledButton
              addOn={`${purchaseOptions.highCost.cost === "FREE" ? "" : "$"}${
                purchaseOptions.highCost.cost
              }`}
              externalLink={purchaseOptions.highCost.link}
              classname="pink"
            >
              Register (APA CE)
            </StyledButton>
            {purchaseOptions.lowCost && (
              <StyledButton
                addOn={`${purchaseOptions.lowCost.cost === "FREE" ? "" : "$"}${
                  purchaseOptions.lowCost.cost
                }`}
                externalLink={purchaseOptions.lowCost.link}
                classname="pink"
              >
                Register
              </StyledButton>
            )}
          </ButtonGroup>
        </Modal>
      )}
    </>
  );
};
