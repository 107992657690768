import React from "react";
import { Link } from "react-router-dom";
import { StyledButton } from "./StyledButton";

export let colorblindnessToggle = false;

export const InternalLink = ({
  to,
  children,
  classname,
  color,
  noFade,
  unstyled,
  style,
}: {
  to: string;
  children: React.ReactNode;
  classname?: string;
  color?: "black" | "red" | "white";
  noFade?: boolean;
  unstyled?: boolean;
  style?: any;
}) => {
  return (
    <Link
      to={to}
      className={`InternalLink ${classname ?? ""} ${
        color ? `${color}Link` : ""
      } ${noFade ? "noFade" : ""} ${
        colorblindnessToggle ? " colorblindLinks" : ""
      } ${unstyled ? " unstyled" : ""}`}
      style={style}
    >
      {children}
    </Link>
  );
};

export const ColorblindButton = () => {
  return (
    <StyledButton
      hover={
        "Some accessibility friendliness added. Adding more as soon as time permits!"
      }
    >
      Colorblind Friendlier
    </StyledButton>
  );
};
