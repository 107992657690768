import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import ChrikiImg from "src/assets/Lyvia.jpg";
import { VideoWrapper } from "src/Components/Common/VideoWrapper";

const title = "Our First Webinar";
const subtitle =
  "Our very own Drs. Greene and Chriki hosted a talk on CPT & PE. Enjoy!";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "August",
  date: "23rd",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "August",
  date: "23rd",
  year: 2022,
};

const creditAmount = 0;

const speakerImage = (
  <img src={ChrikiImg} alt={"Lyvia Chriki"} className={"heightBased"} />
);

export const CPTandPE = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          <VideoWrapper
            videoUrl="https://youtube.com/embed/hSCX2PFqYBg"
            title="Intro to CPT & PE"
            classname={"dark"}
          />
        </div>
      }
      educationalObjectives={["Describe the basics of CPT & PE"]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory",
        description:
          "This webinar is intended for those who are interested in learning about Cognitive Processing Therapy and Prolonged Exposure.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
