import React from "react";

export const References = ({
  references,
}: {
  references: {
    authors?: string;
    title: string;
  }[];
}) => {
  return (
    <div className="LevelOfInstruction">
      <div className="title">References</div>
      {references.map(({ authors, title }) => {
        return (
          <>
            <div className="description">
              {authors}
              <span>{title}</span>
            </div>
          </>
        );
      })}
    </div>
  );
};
