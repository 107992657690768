import React from "react";

export const ConnectingLink = ({
  altEmail,
  altBody,
  altColor,
}: {
  altEmail?: string;
  altBody?: string;
  altColor?: string;
}) => {
  return (
    <a
      className={`ConnectingLink ${altColor}`}
      title={`Email ${altEmail ?? "info@ebpne.com"}`}
      href={`mailto:${altEmail ?? "info@ebpne.com"}`}
    >
      {altBody ?? "FILLIN PHRASE FOR CONTACTING"}
    </a>
  );
};
