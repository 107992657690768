const moment = require("moment-timezone");

export type UsefulDate = {
  month: MonthType;
  date: DateType;
  year: number;
  time?: number;
};

export type MonthType =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";

const MonthMap: { [key in MonthType]: number } = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export type DateType =
  | "1st"
  | "2nd"
  | "3rd"
  | "4th"
  | "5th"
  | "6th"
  | "7th"
  | "8th"
  | "9th"
  | "10th"
  | "11th"
  | "12th"
  | "13th"
  | "14th"
  | "15th"
  | "16th"
  | "17th"
  | "18th"
  | "19th"
  | "20th"
  | "21st"
  | "22nd"
  | "23rd"
  | "24th"
  | "25th"
  | "26th"
  | "27th"
  | "28th"
  | "29th"
  | "30th"
  | "31st";

const DateMap: { [key in DateType]: number } = {
  "1st": 1,
  "2nd": 2,
  "3rd": 3,
  "4th": 4,
  "5th": 5,
  "6th": 6,
  "7th": 7,
  "8th": 8,
  "9th": 9,
  "10th": 10,
  "11th": 11,
  "12th": 12,
  "13th": 13,
  "14th": 14,
  "15th": 15,
  "16th": 16,
  "17th": 17,
  "18th": 18,
  "19th": 19,
  "20th": 20,
  "21st": 21,
  "22nd": 22,
  "23rd": 23,
  "24th": 24,
  "25th": 25,
  "26th": 26,
  "27th": 27,
  "28th": 28,
  "29th": 29,
  "30th": 30,
  "31st": 31,
};

export function usefulDateIsPast(
  usefulDate: UsefulDate,
  hourAdjustment?: number
): boolean {
  let usedTime = moment().tz("America/New_York");
  if (hourAdjustment !== undefined) {
    usedTime.hour(usedTime.hour() + hourAdjustment);
  }

  console.log(usefulDate, usedTime.date(), usedTime.hour());

  if (usefulDate.year < usedTime.year()) return true;
  else if (usefulDate.year === usedTime.year()) {
    if (MonthMap[usefulDate.month] < usedTime.month()) return true;
    else if (MonthMap[usefulDate.month] === usedTime.month()) {
      if (DateMap[usefulDate.date] < usedTime.date()) return true;
      else if (DateMap[usefulDate.date] === usedTime.date()) {
        if (usefulDate.time === undefined) return false;
        return usefulDate.time <= usedTime.hour();
      }
    }
  }
  return false;
}
