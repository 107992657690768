import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import LuceneImg from "src/assets/Lucene.jpg";

const title = "Where Angels Fear to Tread";
const subtitle =
  "When your ED Client is Engaging in Behaviors that Interfere with Treatment";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "June",
  date: "21st",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "June",
  date: "21st",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = <img src={LuceneImg} alt={"Lucene Wisniewski"} />;

export const Wisniewski = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lucene Wisniewski, PhD, FAED",
          image: <div>{speakerImage}</div>,
          affiliation:
            "The Center for Evidence Based Treatment – Ohio & Case Western Reserve University, USA",
          about: (
            <div>
              <div>
                Dr. Wisniewski is an internationally recognized leader in eating
                disorder treatment and Dialectical Behavior Therapy, with more
                than 25 years of clinical and training experience. She has
                taught hundreds of workshops and continuing education seminars
                around the globe and has authored numerous articles in
                peer-reviewed journals and invited book chapters.
              </div>
              <br />
              <div>
                Dr. Wisniewski has also provided consultation and training to
                eating disorder clinics around the globe. In addition, she has
                earned some of the highest awards and accreditations in the
                industry. In 2013, she received the Outstanding Clinician Award
                from the Academy for Eating Disorders, demonstrating her
                leadership in the field and her commitment to providing the best
                solutions for those with eating disorders.
              </div>
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Working with eating disorder clients can be a challenging experience
          for mental health providers due to issues around emotion regulation,
          slow progress and ambivalence toward recovery. Some clients present in
          treatment with emotion dysregulation, crisis behaviors or suicidality
          that result in the therapist failing to push for change, for fear of
          “making things worse”. Other clients present with significant
          emotional inhibition, vulnerability or passivity in the face of
          significant symptoms. Fearing the worst, the therapist may push hard
          for change. These difficulties can also lead to feelings of
          ineffectiveness, increased burnout, and premature treatment
          termination for either the client or the therapist. In DBT, these
          problems and behaviors are considered therapy interfering (TIBs) and
          would be addressed directly, as a failure to address TIBs are thought
          to impede treatment progress.
        </div>
      }
      educationalObjectives={[
        "Identify client behaviors that interfere with progress",
        "Identify therapist behaviors that interfere with progress",
        "Identify skills to that will help the therapist address TIBs between and within session.",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is intended for those who have some familiarity with DBT and treatment of eating disorders",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
