import React from "react";
import { Section } from "src/Components/Common/Section";

export const CatalogLanding = () => {
  return (
    <div className={"CatalogLanding"}>
      <div className={"content"}>
        <Section
          title={"Webinars"}
          subheading={
            "Simple, single day events. Tune in for an hour to watch and learn some new techniques or strength your current skills."
          }
        >
          <div style={{ marginBottom: "20px" }}>
            In January, our own Drs. Green and Chriki hosted this talk on CPT &
            PE. Have a watch and come back later to see what new talks are
            coming up!
          </div>
          <div className="videoWrapper">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/_dxaPHc6pGs"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Section>

        <Section title={"Trainings"}>
          More in-depth, these week long events prep you to PLEASEFILLTHISIN
        </Section>

        <Section
          title={"Intensives"}
          subheading={
            "Intesives require a heavier commitment, in return for strong and lasting knowledge of the topic."
          }
        >
          Spread across multiple weeks, intensives first train you on a subject
          then have you using your new skills. You'll meet up for groups and get
          feedback, so all that knowledge can be best put to real-world work!
        </Section>
      </div>
    </div>
  );
};
