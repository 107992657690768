import React from "react";
import ChrikiImg from "src/assets/Lyvia.jpg";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const title = `Working to Understand, Make Sense Of, and Be Present`;
const subtitle = "Validation and Limits";

const lowCost = {
  cost: 15,
  link: "https://buy.stripe.com/dR68yH6LTfnL9TqbJy",
};
const highCost = {
  cost: 30,
  link: "https://buy.stripe.com/dR69CLgmtgrPe9G3d1",
};

const startTime: UsefulDate = {
  time: 12,
  month: "October",
  date: "3rd",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "October",
  date: "3rd",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img
    src={ChrikiImg}
    alt={"Dr. Chriki"}
    className={"heightBased"}
    style={{ marginLeft: "-10px" }}
  />
);

export const ChrikiSept2022Session4Promo = () => {
  return (
    <HomeTalkPromo
      classname="partOfSeries"
      link={"ChrikiSept2022-Session4"}
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop will focus on helping parents develop the capacity and skill to provide validation while observing their own values and limits."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const ChrikiSept2022Session4Details = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          This workshop will focus on the principles and strategies to guide
          clinicians through their applied work with parents of chronically
          emotionally dysregulated teens and young adults within the DBT model.
          The presentation will address helping parents understand the function
          of their children's behaviors through the lens of Linehan's
          dialectical dilemmas, understand how they may be inadvertently
          reinforcing extreme emotion dysregulation, and shift their own
          behaviors in order to reduce chaos in the household. Core components
          of parent guidance in DBT will be presented, including mindfulness &
          validation, effective responding to children's behaviors, and
          observation of parental values and limits. Case examples will be
          brought in to demonstrate the teaching points and ways to incorporate
          knowledge gleaned from existing literature on family-based
          interventions in DBT and behavioral parent training & guidance.
        </div>
      }
      educationalObjectives={[
        "Describe strategies to employ with parents when teaching them acceptance and validation skills",
        "Describe ways to help parents identify their own behaviors that may be inadvertently reinforcing ineffective behaviors in their children through parent chain analyses",
        "Describe key components to behavior plans to construct with parents",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This workshop is intended for participants who have foundational training in DBT or general knowledge DBT and behavioral principles",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      notAWebinar={"workshop"}
    />
  );
};
