import React from "react";
import ChrikiImg from "src/assets/Lyvia.jpg";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const title = "In or out, and for what?";
const subtitle = "Generating Goals and Commitment";

const lowCost = {
  cost: 15,
  link: "https://buy.stripe.com/aEUaGP1rzejH5Da14R",
};
const highCost = {
  cost: 30,
  link: "https://buy.stripe.com/3cs6qz1rzdfDd5C5l8",
};

const startTime: UsefulDate = {
  time: 12,
  month: "September",
  date: "26th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "September",
  date: "26th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img
    src={ChrikiImg}
    alt={"Dr. Chriki"}
    className={"heightBased"}
    style={{ marginLeft: "-10px" }}
  />
);

export const ChrikiSept2022Session326thPromo = () => {
  return (
    <HomeTalkPromo
      classname="partOfSeries"
      link={"ChrikiSept2022-Session3-26th"}
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop will focus on engaging parents of adolescents and young adults in parent-guidance in DBT."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const ChrikiSept2022Session326thDetails = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Helping parents identify their goals in parent guidance sessions can
          be difficult. This is because, understandably, parents are focused on
          wanting their children to change and feel better, and for things in
          the household to be less chaotic. When children are younger, focusing
          mostly on modifying and changing the child's behaviors can be a
          reasonable goal, as parents exert a high level of control in early
          ages. <br />
          However, as children reach adolescence and emerging adulthood, parents
          are faced more than ever with the reality that they are limited in the
          extent to which they are able to change their children's behaviors
          directly through behavior modification techniques. While having a
          sense of behavioral principles is still extremely important, focusing
          solely on their children's behaviors may not prove to be as useful as
          working on focusing on their own behaviors and values. <br />
          <br />
          Helping parents identify their own values as parents, as well as begin
          to identify their own limits, and letting that guide their goals for
          treatment is important. In this way, parents can begin to change their
          own responses to their children; focusing on the kind of parents they
          want to be -- what they will model and teach to their children.
          <br />
          <br />
          This workshop will focus on engaging parents of adolescents and young
          adults in parent-guidance in DBT. We will focus on helping parents
          define their goals for treatment through working to understand their
          values as parents and providing some relevant psychoeducation on
          emotion dysregulation and behaviorism, while also engaging in
          classical DBT commitment strategies.
        </div>
      }
      educationalObjectives={[
        "Describe strategies to use when guiding parents to define their goals for their work based on their values as parents",
        "Describe ways to help parents distinguish between their own goals as parents and their goals for their children",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This workshop is intended for participants who have foundational training in DBT or general knowledge DBT and behavioral principles",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      notAWebinar={"workshop"}
    />
  );
};
