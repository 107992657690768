import React from "react";
import JohnsonImg from "src/assets/Johnson.jpeg";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";
import { References } from "./References";

const title = `Getting to Acceptance`;
const subtitle = ` Working with Difficult Emotions 'in the room' Using Principles from Exposure, Inhibitory Learning, and Mindfulness-Based Treatments`;

const lowCost = {
  cost: 25,
  link: "https://buy.stripe.com/4gweX53zHgrPc1y154",
};
const highCost = {
  cost: 45,
  link: "https://buy.stripe.com/7sI3en1rz5Nb6He5lj",
};

const startTime: UsefulDate = {
  time: 12,
  month: "April",
  date: "3rd",
  year: 2023,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "April",
  date: "3rd",
  year: 2023,
};

const citations = [
  {
    authors:
      "Craske, M. G., Treanor, M., Conway, C. C., Zbozinek, T., & Vervliet, B. (2014).",
    title:
      "Maximizing exposure therapy: An inhibitory learning approach. Behaviour research and therapy, 58, 10-23.",
  },
  {
    authors:
      "Gloster, A. T., Walder, N., Levin, M. E., Twohig, M. P., & Karekla, M. (2020).",
    title:
      "The empirical status of acceptance and commitment therapy: A review of meta-analyses. Journal of Contextual Behavioral Science, 18, 181-192.",
  },
  {
    authors:
      "Levin, M. E., Krafft, J., Hicks, E. T., Pierce, B., & Twohig, M. P. (2020).",
    title:
      "A randomized dismantling trial of the open and engaged components of acceptance and commitment therapy in an online intervention for distressed college students. Behaviour Research and Therapy, 126, 103557.",
  },
];

const creditAmount = 1.5;

const speakerImage = <img src={JohnsonImg} alt={"Daniel Johnson, Ph.D."} />;

export const JohnsonPromo = () => {
  return (
    <HomeTalkPromo
      link={"Johnson"}
      speakers={[
        {
          name: "Daniel Johnson, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop will discuss strategies for promoting acceptance of difficult thoughts and feelings in sessions for both clients and clinicians."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const JohnsonDetails = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Daniel Johnson, Ph.D.",
          extraTitling: "",
          image: <div>{speakerImage}</div>,
          affiliation: "",
          about: (
            <div>
              Daniel Johnson is a licensed clinical psychologist in
              Massachusetts. He received his Ph.D. from the University of
              Colorado, Boulder, completed his predoctoral internship at McLean
              Hospital/Harvard Medical School, and completed his postdoctoral
              fellowship at the VA Boston Healthcare System/Harvard Medical
              School. Dr. Johnson has previously worked as a staff psychologist
              at Massachusetts General Hospital's outpatient substance use
              disorders (SUDs) clinic and as the Director of the Acceptance and
              Commitment Therapy (ACT) Program at Boston Child Study Center. He
              is now in private practice, providing evidence-based therapy to
              adults and offering training to mental health professionals and
              organizations. Dr. Johnson has trained clinicians internationally
              on evidence-based treatments, with a focus on mindfulness and
              acceptance-based behavioral therapies (ACT, DBT, CBT). He has been
              designated by the Association for Contextual Behavioral Science as
              a Peer Reviewed ACT Trainer. Dr. Johnson also teaches courses on
              SUDs and evidence-based clinical practice at William James College
              and he is the Education and Facilitation Lead at Tend Health,
              Inc., an organization that provides behavioral health and training
              tailored to the needs of healthcare professionals. More details
              about Dan and his practice are at{" "}
              <a href="https://www.danielpjohnsonphd.com/services-1">
                his site - here
              </a>
              .
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Over 40 years of empirical data support the principles of Acceptance
          and Commitment Therapy (ACT) and the importance of accepting difficult
          inner experiences in the process of meaningful behavior change.
          However, understanding acceptance conceptually is very different from
          experiencing it! When mental health clinicians encounter clients'
          difficult inner experiences in session - intense feelings, strong
          urges, powerful and recursive thoughts - it can be difficult,
          intimidating and down-right scary to encourage clients to “make room”
          for their pain. "What if they get mad at me and don't come back? What
          if they actually <i>can't</i> handle it? What if <i>I</i> can't handle
          it?"
          <br />
          <br />
          The core principles of Inhibitory Learning and exposure therapy
          provide a strong foundation for the practice of acceptance. These
          principles can support clinicians as they introduce clients to
          acceptance, guide them through it in sessions and as they empower
          clients to practice acceptance in their lives. Furthermore, clinicians
          can apply exposure principles to their own unhelpful responses to
          client's pain and use them to stay present and steady in moments when
          urges for experiential avoidance are strong.
          <br />
          <br />
          Through didactics, case examples and experiential practices, this
          intermediate workshop will review the principles of acceptance and the
          principles of exposure as applied to thoughts and feelings. It will
          also introduce practical strategies to support the practice of the
          acceptance in sessions, both for the client and the clinician’s
          themselves. It is expected that participants will have prior knowledge
          of the theory and practice of ACT.
          <br />
          <br />
          <References references={citations} />
        </div>
      }
      educationalObjectives={[
        "Identify the principles and mechanisms of acceptance, exposure and inhibitory learning",
        "Identify and design an exposure plan for experiential avoidance",
        "Practice techniques designed to promote the acceptance of thoughts and feelings",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is appropriate for clinicians with prior knowledge of the theory and practice of ACT.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
