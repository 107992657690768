import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import ChrikiImg from "src/assets/Lyvia.jpg";

const title = "“Why is my child acting this way!?!”";
const subtitle = "Parent guidance through the lens of dialectical dilemmas";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "December",
  date: "15th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "December",
  date: "15th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img src={ChrikiImg} alt={"Lyvia Chriki"} className={"heightBased"} />
);

export const ChrikiDec2021 = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Linehan (1993) discusses core dialectical dilemmas faced by
          individuals with BPD that are invaluable in conceptualizing cases and
          making sense of behavior patterns rooted in emotion dysregulation.
          These include: emotional vulnerability & self-invalidation,
          unrelenting crisis & inhibited grieving, and active passivity &
          apparent competence. While DBT treatments that address family
          interventions often suggest targeting validation and behavior
          modification strategies in general, they sometimes leave out a
          specific discussion of how these dialectical dilemmas play a role and
          may be reinforced in family relationships. Parents of chronically
          dysregulated teens or young adults may be inadvertently reinforcing
          their children’s tendency to sway between the extremes of these
          dialectics. For example, parents may err on the side of overly
          engaging in problem solving for their children due to fragilizing
          them, thereby reinforcing active passivity; or, they may model
          emotional suppression, thereby reinforcing apparent competence or
          inhibited grieving, and so on. Conceptualizing the parent-child
          transaction through the lense of the core dialectical dilemmas can be
          very helpful in helping parents make sense of their children’s
          behavior and in giving parents clear guidance in terms of their
          responses to their children.
        </div>
      }
      educationalObjectives={[
        "List and describe the core dialectical dilemmas presented in Linehan’s 1993 text",
        "Describe parents’ behaviors that reinforce their children’s tendency to struggle with these dialectical dilemmas",
        "Describe intervention strategies to employ with parents to help them respond more effectively to dysregulated behaviors as seen through the lense of dialectical dilemmas",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is intended for those who have foundational training in DBT and are actively practicing DBT.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
