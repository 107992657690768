import React, { useEffect } from "react";

export const Modal = ({
  children,
  onClose,
  title,
}: {
  children: React.ReactNode;
  onClose: () => void;
  title: string;
}) => {
  useEffect(() => {
    document.body.className += "modal-open";

    return () => {
      document.body.className = "";
    };
  }, []);

  const consumeClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  return (
    <div className="Modal" onClick={onClose}>
      <div className="modalBody" onClick={consumeClick}>
        <div className={"title"}>
          {title}
          <div className="closeX" onClick={onClose}>
            X
          </div>
        </div>
        <div className="scrollArea">{children}</div>
      </div>
    </div>
  );
};
