import React from "react";
import { Section } from "./Common/Section";
import { ConnectingLink } from "./Features/Connecting/ConnectingLink";

export const FourOhFour = () => {
  return (
    <Section title={"Location not found..."}>
      Oh dear! We tried finding what you're looking for, but can't locate it
      anywhere. If there should have been something here, please{" "}
      <a href="mailto:info@ebpne.com" target="_blank">
        let us know
      </a>
      . Otherwise, the menu above should take you wherever you need to go.
    </Section>
  );
};
