import React from "react";
import { InternalLink } from "./Common/InternalLink";
import logo from "src/assets/logo.svg";
import smallLogo from "src/assets/EBPNE-logo-2.svg";
import { useLocation } from "react-router";

export const MainMenu = () => {
  const routerLocation = useLocation();
  const onHomeScreen = routerLocation.pathname === "/";

  return (
    <div className={"MainMenu"}>
      <div className="topLine">
        <InternalLink
          to="/"
          classname={`logoHolder ${onHomeScreen ? "home" : ""}`}
          unstyled={true}
        >
          {onHomeScreen ? (
            <img src={logo} alt="EBPNE" />
          ) : (
            <img src={smallLogo} alt="EBPNE" />
          )}
        </InternalLink>

        <div className={"rightMenu"}>
          <InternalLink to={"/"} classname={"item"}>
            Home
          </InternalLink>
          <InternalLink to={"/talks/"} classname={"item"}>
            <span className="mobileCut">Public</span> Talks
          </InternalLink>
          <InternalLink to={"/about/"} classname={"item"}>
            About
          </InternalLink>
        </div>
      </div>
      {onHomeScreen && (
        <div>
          <div className="title">
            <div className="name">Evidence-based Practices of New England</div>
            <div className="break"></div>
            <div className="slogan">Learn. Navigate the complex</div>
          </div>
        </div>
      )}
    </div>
  );
};
