import React from "react";
import { BrookKrausPromo } from "../Talks/BrookKraus";
import { BrookKrausMay2024Promo } from "../Talks/BrookKraus-May2024";
import { UsefulDate, usefulDateIsPast } from "src/utils";
import { ChrikiAugust2024Promo } from "../Talks/Chriki-Aug2024";

const shortTalkStart: UsefulDate = {
  time: 12,
  month: "February",
  date: "6th",
  year: 2024,
};

export const HomePage = () => {
  return (
    <div className="HomePage">
      <div className="comingSoon">
        <div className="title">COMING UP SOON</div>
        <div className="comingTalks">
          <ChrikiAugust2024Promo />
        </div>
      </div>
    </div>
  );
};
