import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import HarnedImg from "src/assets/Harned.jpeg";

const title = "Addressing Complicated Issues in Trauma Treatment";
const subtitle = "Dissociation and Fragmented Trauma Memories";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "February",
  date: "28th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "February",
  date: "28th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = <img src={HarnedImg} alt={"Melanie Harned"} />;

export const Harned = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Melanie Harned, Ph.D., ABPP",
          image: <div>{speakerImage}</div>,
          affiliation: "VA Puget Sound & University of Washington",
          about: (
            <div>
              Dr. Harned is a Psychologist and the Coordinator of the DBT
              Program at the VA Puget Sound Health Care System as well as an
              Associate Professor in the Department of Psychiatry and Behavioral
              Sciences and Adjunct Associate Professor in the Department of
              Psychology at the University of Washington. She previously worked
              as the Research Director of Dr. Marsha Linehan’s Behavioral
              Research and Therapy Clinics at the University of Washington
              (2006-2018), Director of Research and Development for Behavioral
              Tech, LLC (2014-2017), and Director of Behavioral Tech Research,
              Inc. (2013-2016).
              <br />
              Her research focuses on the development and evaluation of the DBT
              Prolonged Exposure protocol for PTSD as well as methods of
              disseminating and implementing this and other evidence-based
              treatments into clinical practice. She regularly provides training
              and consultation nationally and internationally in DBT and DBT PE
              and has published numerous articles and book chapters on these
              treatments. Most recently, she published the manual on DBT-PE.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          This webinar will provide training in how to address two complicated
          issues that often arise when treating PTSD among clients with multiple
          comorbidities and histories of complex trauma. Many of these clients
          struggle with significant dissociation and some may present with
          Dissociative Identity Disorder. In addition, these clients often have
          difficulty remembering the details of their traumatic experiences or
          may “recover” trauma details or memories during treatment. These
          problems can complicate trauma-focused treatment and impede progress,
          which makes it important for therapists to know how to address them.
          At the same time, research on how to manage these challenging problems
          during PTSD treatment is limited. This webinar will discuss options
          for addressing dissociation and trauma memory issues in a
          principle-driven manner utilizing strategies derived from behavioral
          theory, dialectics, and acceptance-based approaches. Specific examples
          will be provided of addressing these complicated issues within the
          context of the DBT Prolonged Exposure (DBT PE) protocol for PTSD.
        </div>
      }
      educationalObjectives={[
        "Describe strategies to address dissociation during trauma-focused treatment",
        "Identify options for working with clients with fragmented trauma memories",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate to Advanced",
        description:
          "This webinar is intended for those who have had moderate experience doing trauma treatment with this population and also, ideally, have a background in DBT/behavioral treatments.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
