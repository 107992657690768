import React from "react";
import ChrikiImg from "src/assets/Lyvia.jpg";
import { InternalLink } from "src/Components/Common/InternalLink";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const title = "The Big Picture";
const subtitle = "Case Conceptualization in DBT Parent Guidance";

const lowCost = {
  cost: 15,
  link: "https://buy.stripe.com/00geX59Y5cbz0iQcNy",
};
const highCost = {
  cost: 30,
  link: "https://buy.stripe.com/28og19eel8Zn3v2dRB",
};

const startTime: UsefulDate = {
  time: 12,
  month: "September",
  date: "19th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "September",
  date: "19th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img
    src={ChrikiImg}
    alt={"Dr. Chriki"}
    className={"heightBased"}
    style={{ marginLeft: "-10px" }}
  />
);

export const ChrikiSept2022Session2Promo = () => {
  return (
    <HomeTalkPromo
      classname="partOfSeries"
      link={"ChrikiSept2022-Session2"}
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop will focuse on important points to consider when assessing and formulating parent cases in DBT."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};

export const ChrikiSept2022Session2Details = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          As DBT therapists working with parents, we are interested in
          understanding what is reinforcing and maintaining emotion
          dysregulation in the household in order to understand the best points
          of intervention. In this workshop, we will discuss important points to
          consider in the assessment process when beginning our work with
          parents. Considerations will include the use of chain analyses to
          understand patterns of interaction and transactions among family
          members, contextual and cultural issues, identifying parenting styles,
          and making use of Linehan's and Miller & Rathus's models of
          dialectical dilemmas in order to understand how parents may be
          inadvertently reinforcing dysregulation.
        </div>
      }
      educationalObjectives={[
        "Describe contextual, including cultural, variables to take into account when conducting chain analyses with parents and conceptualizing cases",
        "Describe ways that parents may be inadvertently reinforcing ineffective behaviors in their children",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory to Intermediate",
        description:
          "This workshop is intended for participants who have foundational training in DBT or general knowledge DBT and behavioral principles",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      notAWebinar={"workshop"}
    />
  );
};
