import React from "react";
import ChapmanImg from "src/assets/Chapman-Alex.jpg";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import { HomeTalkPromo } from "../Home/HomeTalkPromo";

const altDetailsLink =
  "https://app.ce-go.com/phone-coaching-in-dbt-navigating-things-effectively";

const title = `Sustainable Phone Coaching in DBT`;
const subtitle = "Managing Clinical Challenges and Suicide Risk Effectively";

const lowCost = {
  cost: 90,
  link: "https://buy.stripe.com/00g3en2vD1wV4z6dRL",
};
const highCost = {
  cost: 175,
  link: "https://buy.stripe.com/7sI16fc6d5Nb3v24ha",
};

const startTime: UsefulDate = {
  time: 11,
  month: "April",
  date: "24th",
  year: 2023,
};
const endTime: UsefulDate = {
  time: 17.5,
  month: "April",
  date: "24th",
  year: 2023,
};

const creditAmount = 5.5;

const speakerName = "Alexander L. Chapman, Ph.D., R.Psych";
const speakerImage = <img src={ChapmanImg} alt={"Dr. Chapman"} />;
const speakerAffiliation =
  "Simon Fraser University & DBT Centre of Vancouver, Inc.";

const suggestedReadings = [
  "Chapman, A.L. (2019). Phone coaching in dialectical behavior therapy. New York:  The Guilford Press",
  "Linehan, M.M. (1993). Cognitive-behavioral treatment of borderline personality  disorder. New York: The Guilford Press",
  "Linehan, M.M. (2015). DBT skills training manual, 2nd edition. New York: The  Guilford Press",
];

export const ChapmanPromo = () => {
  return (
    <HomeTalkPromo
      link={"Chapman"}
      altLink={altDetailsLink}
      speakers={[
        {
          name: speakerName,
          image: <div>{speakerImage}</div>,
          affiliation: speakerAffiliation,
          about: <div />,
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      miniAbout={
        "This workshop aims to help  clinicians learn how to effectively navigate and sustain phone coaching in their practice and to deal with common challenges. This event will not be recorded."
      }
      numberOfCredits={creditAmount}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      recordingWillBeAvailable={false}
    />
  );
};

export const ChapmanDetails = () => {
  return (
    <DetailsPage
      altLink={altDetailsLink}
      speakers={[
        {
          name: speakerName,
          image: <div>{speakerImage}</div>,
          affiliation: speakerAffiliation,
          about: (
            <div>
              Alexander L. Chapman, PhD, RPsych, is Professor and Clinical
              Science Area Coordinator in the Department of Psychology at Simon
              Fraser University (SFU), in Burnaby, British Columbia, Canada, and
              president of the Dialectical Behavior Therapy (DBT) Centre of
              Vancouver. Dr. Chapman directs the Personality and Emotion
              Research Laboratory at SFU, where he studies the role of emotion
              regulation in borderline personality disorder, self-harm,
              impulsivity, and other behavioral problems. He has published
              numerous scientific articles chapters, and books; presents widely
              at scientific conferences; and is on the editorial boards of
              Personality Disorder: Theory, Research, and Treatment, Journal of
              Personality Disorders, and Behavior Therapy. Dr. Chapman is a DBT
              Trainer and Consultant with Behavioral Tech. He regularly gives
              local, national, and international workshops and invited talks on
              DBT and the treatment of borderline personality disorder; has
              consulted with and trained clinicians in Canada, the United
              States, and the United Kingdom; and trains and supervises clinical
              psychology students. He is a recipient of awards including the
              Young Investigator’s Award from the National Education Alliance
              for Borderline Personality Disorder, the Early Career Scientist
              Practitioner Award from the Canadian Psychological Association,
              and an 8-year Career Investigator Award from the Michael Smith
              Foundation for Health Research.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Dialectical behavior therapy (DBT; Linehan, 1993; 2015) is a
          well-established, comprehensive cognitive behavioral treatment for
          people struggling with complex mental health challenges. DBT consists
          of four treatment components, including weekly individual therapy,
          weekly group skills training, a therapist consultation team, and
          telephone consultation, often referred to as phone coaching.
          <br />
          The focus of this workshop will be on phone coaching (see Linehan,
          1993; see also, Chapman, 2019). The aim of phone coaching is to help
          clients generalize what they’re learning in therapy to everyday life
          situations. Typically, the individual therapist is available between
          sessions to discuss how the client might apply skills in challenging
          situations. Phone coaching often occurs by phone but can also include
          text or other forms of between-session communication. Although
          therapists and clients often find phone coaching to be very helpful,
          therapists often need extra guidance to navigate this component of DBT
          effectively and to manage challenges.
          <br />
          This workshop is intended for an intermediate audience of clinicians
          who have some familiarity and experience with phone coaching. The
          primary aim is to help clinicians learn how to effectively navigate
          and sustain phone coaching in their practice and to deal with common
          challenges. The workshop will include didactic presentation,
          discussion, role-play modelling and practice, and possibly video/audio
          components.
        </div>
      }
      educationalObjectives={[
        "Describe and discuss core DBT principles applied to phone coaching",
        "Describe and discuss ways to make phone coaching sustainable",
        "Describe assessment strategies to use when assessing suicide risk during phone coaching",
        "Describe and discuss suicide risk management strategies to utilize in phone coaching",
        "Describe therapy interfering behaviors that sometimes arise in the context of phone coaching",
        "Describe and discuss how to navigate common special situations and challenges in  phone coaching such as therapy interfering  behavior",
      ]}
      suggestedReadings={suggestedReadings}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This workshop is intended for participants who have foundational training in DBT or general knowledge DBT and behavioral principles",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
      recordingWillBeAvailable={false}
      notAWebinar="workshop"
      scheduleLink="/schedule/Chapman"
    />
  );
};
