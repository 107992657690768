import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import FitzpatrickImg from "src/assets/Fitzpatrick.png";

const title = "Emotion Dysregulation as an Interpersonal Process";
const subtitle =
  "Introducing a Novel Model and Intervention for Borderline Personality Disorder";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "June",
  date: "7th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13,
  month: "June",
  date: "7th",
  year: 2022,
};

const creditAmount = 1;

const speakerImage = <img src={FitzpatrickImg} alt={"Skye Fitzpatrick"} />;

export const Fitzpatrick = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Skye Fitzpatrick, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "York University",
          about: (
            <div>
              Dr. Skye Fitzpatrick completed her PhD in 2018 at Ryerson
              University in the Borderline Personality Disorder and Emotion
              Processing Laboratory under Dr. Janice Kuo’s supervision. Her
              research at Ryerson focused on the nature and treatment of
              borderline personality disorder (BPD), with a specific focus on
              dialectical behaviour therapy (DBT). Dr. Fitzpatrick’s training at
              Ryerson supported her in achieving numerous awards, including the
              Governor General’s Academic Gold Medal Award, the American
              Psychological Association Dissertation Research Award, and the
              Dennis Mock Student Leadership Award, among others. Following
              graduate school, Dr. Fitzpatrick completed her predoctoral
              internship in Seattle at the University of Washington under the
              research mentorship of Dr. Debra Kaysen. During her internship,
              she received research and clinical training in the treatment of
              posttraumatic stress disorder (PTSD), which often co-occurs with
              BPD, and was awarded the Nancy Robinson Award for Outstanding
              Overall Achievement for her contributions to the training program.
              Dr. Fitzpatrick continued to pursue training in both BPD and PTSD
              treatment research during her postdoctoral fellowship in the
              Center of Alcohol Studies at Rutgers University under the
              mentorship of Dr. Denise Hien and Dr. Shireen Rizvi.
              <br />
              <br />
              Her current research focuses on identifying ways to optimize,
              expedite, and broaden access to BPD and PTSD treatments on their
              own and as they co-occur. In the interest of optimizing and
              expediting BPD and PTSD treatments, Dr. Fitzpatrick has become
              particularly focused on harnessing the power of relationships
              through the development and study of conjoint or dyadic
              interventions. In the interest of broadening access to BPD and
              PTSD interventions, she has become interested in developing and
              studying online, remote-delivered, and brief interventions.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      videoLink={
        "https://www.youtube.com/watch?v=2sQNksmXvBM&feature=emb_title"
      }
      aboutSection={
        <div>
          Leading theories suggest that borderline personality disorder (BPD) is
          predominantly characterized by emotion dysregulation, which involves
          disrupted emotion processes and difficulties regulating them.
          Accordingly, existing evidence-based BPD treatments are intrapersonal
          in nature and often focus on teaching people with BPD new ways to
          modulate emotion. However, experimental research examining emotion
          dysregulation processes in BPD have produced mixed findings, and some
          suggest that such processes may only be evident in BPD response to
          interpersonal, rather than intrapersonal, stressors. In this talk, Dr.
          Fitzpatrick will present the Borderline Interpersonal Affective
          Systems (BIAS) model– a novel model of BPD that synthesizes emotional
          and interpersonal theories of the disorder to elucidate how BPD is
          maintained within a cycle of emotion dysregulation and relationship
          dysfunction. Dr. Fitzpatrick will then provide an overview of the
          empirical support for the BIAS model and its implications for BPD
          treatment. Time permitting, she will describe a novel treatment that
          herself and her colleagues have been developing based on the BIAS
          model that is delivered conjointly to individuals with BPD and their
          intimate partners to target both emotion dysregulation and
          relationship dysfunction simultaneously. The talk will conclude with
          discussing directions for future research in this nascent field.
        </div>
      }
      educationalObjectives={[
        "Describe the mechanisms by which BPD is maintained, based on the Interpersonal Affective Systems Model of BPD.",
        "Describe core research data that supports this model and has led to its development",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Introductory to Intermediate",
        description:
          "This webinar is intended for those familiar with earlier theories and research on the etiology of Borderline Personality Disorder",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
