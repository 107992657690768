import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import ChrikiImg from "src/assets/Lyvia.jpg";

const title = "Parent Consultation & Guidance in DBT";
const subtitle = "Helping parents provide a validating and stable environment";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "August",
  date: "23rd",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "August",
  date: "23rd",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img src={ChrikiImg} alt={"Lyvia Chriki"} className={"heightBased"} />
);

export const Chriki = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Lyvia Chriki, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Boston Evidence Supported Treatments & EBPNE",
          about: (
            <div>
              Dr. Chriki is the founder of Boston Evidence Supported Treatments.
              She specializes in providing Dialectical Behavior Therapy (DBT),
              treatment for trauma and PTSD, parent guidance & consultation,
              anxiety and issues of over control of emotions, as well as
              depression.
              <br />
              <br />
              Dr. Chriki received her doctorate degree from the Ohio State
              University and completed her postdoctoral training at the Brown
              University Clinical Psychology Training Consortium. She is on the
              board of the New England Personality Disorder Association (NEPDA)
              and a founding partner of Evidence Based Practices of New England.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          As clearly stated in Linehan’s Biosocial Model, a pivotal variable
          that contributes to and maintains emotions dysregulation is the
          person’s environment. This is especially important to keep in mind
          when treating children, adolescents, or young adults still living at
          home. Helping parents respond more effectively to their children and
          cultivate skills to this end could be extremely important in helping
          the child change. This webinar will address providing parent guidance
          and consultation based on DBT principles to parents of teens and young
          adults who are struggling. The webinar will discuss both the content
          of skills parents could benefit from learning and applying to their
          parenting, as well as effective approaches to take with parents when
          teaching them skills and helping them move both towards acceptance and
          change in their behaviors with their children. Some principles from
          Acceptance and Commitment Therapy (ACT) will also be discussed as a
          way of complimenting DBT principles to provide effective guidance to
          parents. The talk will draw on case examples and encourage discussion
          and questions.
        </div>
      }
      educationalObjectives={[
        "Identify core DBT skills to teach to parents when providing DBT parent guidance & consultation",
        "Identify effective strategies to apply with parents in the context of a parent-guidance session",
        "Identify principles from ACT to incorporate into DBT parent-guidance",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is intended for those who have some familiarity with DBT and parent coaching.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
