import React from "react";
import { UsefulDate } from "src/utils";
import { StyledButton } from "../StyledButton";

export const DateTimeDisplayer = ({
  date,
  secondDate,
  classname,
  scheduleLink,
}: {
  date: UsefulDate;
  secondDate?: UsefulDate;
  classname?: string;
  scheduleLink?: string;
}) => {
  return (
    <>
      <div className={`DateTimeDisplayer ${classname ?? ""}`}>
        <div className="date">
          {date.month} {date.date}, {date.year}
        </div>
        {!!date.time && (
          <div className="times">
            {Math.floor(date.time) % 12 || 12}:{date.time % 1 > 0 ? "30" : "00"}{" "}
            {date.time < 12 ? "am" : "pm"}
            {!!secondDate?.time && (
              <div className="secondDate">
                -{" "}
                {(secondDate.month !== date.month ||
                  secondDate.date !== date.date ||
                  secondDate.year !== date.year) && (
                  <div className="date">
                    {secondDate.month} {secondDate.date}, {secondDate.year}
                  </div>
                )}
                <div className="times">
                  {Math.floor(secondDate.time) % 12 || 12}:
                  {secondDate.time % 1 > 0 ? "30" : "00"}
                  {secondDate.time < 12 ? "am" : "pm"}
                </div>
              </div>
            )}
            <div style={{ marginLeft: "4px" }}>EST</div>
          </div>
        )}
        {!!scheduleLink && (
          <div style={{ marginLeft: "20px" }} className={"notOnPhone"}>
            <StyledButton internalLink={`${scheduleLink}`}>
              {scheduleLink.includes("/schedule/")
                ? "Check the Schedule"
                : "Details"}
            </StyledButton>
          </div>
        )}
      </div>

      {!!scheduleLink && (
        <div className="phoneOnly">
          <StyledButton
            style={{ margin: "10px 0" }}
            internalLink={`${scheduleLink}`}
          >
            {scheduleLink.includes("/schedule/")
              ? "Check the Schedule"
              : "Details"}
          </StyledButton>
        </div>
      )}
    </>
  );
};
