import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import SayrsImg from "src/assets/Sayrs.jpeg";

const title = "DBT Consultation Team Effectiveness";
const subtitle =
  "Overcoming Struggles to Maintain the Effectiveness of the Team";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "October",
  date: "25th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "October",
  date: "25th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = <img src={SayrsImg} alt={"Jennifer Sayrs"} />;

export const SayrsTeamEffectiveness = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Jennifer Sayrs, Ph.D., ABPP",
          image: <div>{speakerImage}</div>,
          affiliation: "Evidence Based Treatment Centers of Seattle (EBTCS)",
          about: (
            <div>
              <div>
                Jennifer Sayrs, PhD, ABPP is the Executive Director and
                co-founder of the Evidence Based Treatment Centers of Seattle
                (EBTCS). At EBTCS, she directs the DBT Center, one of the first
                DBT programs in the nation to be certified by the Linehan Board
                of Certification. She is the co-author of the book DBT Teams:
                Development and Practice, written with the DBT treatment
                developer, Dr. Marsha Linehan.
              </div>
              <br />
              <div>
                Dr. Sayrs completed her graduate training at the University of
                Nevada, Reno, where she studied DBT and behavioral theory,
                followed by a postdoctoral fellowship with Dr. Linehan at the
                University of Washington, where she was the clinical coordinator
                for Dr. Linehan’s DBT research clinic. She has served as a
                research therapist and adherence coder on several DBT trials;
                she is also a trainer for Behavioral Tech, providing a wide
                range of DBT workshops around the world. In addition to 25 years
                of experience providing DBT, she also provides CBT for a variety
                of anxiety-related problems. Dr. Sayrs is certified by the
                Linehan Board of Certification and the American Board of
                Cognitive and Behavioral Psychology and is a licensed
                psychologist in the state of Washington.
              </div>
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          This webinar is intended to be a follow-up to our initial DBT Teams
          webinar given over the summer, delving deeper. The agenda will be
          guided by questions and issues posed in advance by participants.
          <br />
          <br />
          Belonging to a DBT consultation team is an essential component of DBT,
          as it provides support for DBT therapists and helps therapists stay
          adherent to the model. However, there are times where teams or
          individual team members struggle in a way that may interfere with the
          effectiveness of the consultation team. Team members may fall on
          opposite sides of dialectics and struggle to synthesize perspectives.
          At times, members may find themselves holding very closely to rules
          and structure and inadvertently fall away from the guiding principles
          of DBT. Other times, teams may struggle to find ways to fully support
          their members. Individuals may struggle to show vulnerability or have
          difficulty identifying what might be useful to share and discuss in
          team.
        </div>
      }
      educationalObjectives={[
        "Identify obstacles that can arise on DBT consultation teams that interfere with the effectiveness of the DBT consultation team",
        "Identify strategies to employ when approaching problems that arise on DBT consultation teams",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is intended for those who have experience being on or leading a DBT consultation team.",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
