import React, { useState } from "react";
import { ButtonGroup } from "../ButtonGroup";
import { DateTimeDisplayer } from "./DateTimeDisplayer";
import { ExternalLink } from "../ExternalLink";
import { Modal } from "../Modal";
import { StyledButton } from "../StyledButton";
import { UsefulDate, usefulDateIsPast } from "src/utils";
import { ContinuingEdCreditTag } from "../ContinuingEdCreditTag";
import { ConnectingLink } from "src/Components/Features/Connecting/ConnectingLink";

export const WatchRecordingPrompt = ({ link, altClassname }: { link: string; altClassname?: string }) => {
  return (
    <StyledButton externalLink={link} classname={altClassname ?? "clear"}>
      WATCH RECORDING
    </StyledButton>
  );
};

export const MissedRecordingPrompt = () => {
  return (
    <div
      style={{
        background: "rgba(255,255,255,.3)",
        marginLeft: "15px",
        padding: "5px 10px",
      }}
    >
      Missed registration? The recording will be coming to the{" "}
      <ExternalLink to="https://www.youtube.com/channel/UCiQIiTgcxtSBNs8o9seKjiA">EBPNE CHANNEL</ExternalLink> soon!
    </div>
  );
};

export const PurchaseRecordingPrompt = () => {
  return (
    <div
      style={{
        background: "rgba(255,255,255,.3)",
        marginLeft: "15px",
        padding: "5px 10px",
      }}
    >
      Missed registration?
      <ConnectingLink altColor="lighter" altBody=" The recording is available for purchase!" />
    </div>
  );
};

type InstructionLevel = {
  level: "Introductory" | "Introductory to Intermediate" | "Intermediate" | "Intermediate to Advanced" | "Advanced";
  description: string;
};

export type PurchaseOptions = {
  lowCost?: {
    cost: number | "FREE";
    link: string;
  };
  highCost: {
    cost: number | "FREE" | "";
    link: string;
  };
};

export type Speaker = {
  name: string;
  extraTitling?: string;
  affiliation: string;
  image: React.ReactNode;
  about: React.ReactNode;
  smallImage?: boolean;
};

export type DetailsPageProps = {
  altLink?: string;
  speakers: Speaker[];
  start: UsefulDate;
  timeRange?: UsefulDate;
  end?: UsefulDate;
  title: string;
  subtitle?: string;
  aboutSection: React.ReactNode;
  aboutSpecial?: React.ReactNode;
  educationalObjectives: string[];
  numberOfCredits: number;
  instructionLevel: InstructionLevel;
  purchaseOptions: PurchaseOptions;
  suggestedReadings?: string[];
  recordingWillBeAvailable?: boolean;
  recordingIsPurchasable?: boolean;
  videoLink?: string;
  notAWebinar?: string;
  scheduleLink?: string;
  conflicts?: string[];
  altCutOffHours?: number;
};

export const DetailsPage = ({
  altLink,
  speakers,
  start,
  timeRange,
  end,
  title,
  subtitle,
  aboutSection,
  aboutSpecial,
  educationalObjectives,
  numberOfCredits,
  instructionLevel,
  purchaseOptions,
  suggestedReadings,
  recordingWillBeAvailable,
  recordingIsPurchasable,
  videoLink,
  notAWebinar,
  scheduleLink,
  conflicts,
  altCutOffHours,
}: DetailsPageProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openRegistrationModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div className={"DetailsPage"}>
        <div className="buttonRow">
          <StyledButton internalLink="/" classname="clear">
            GO BACK
          </StyledButton>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {videoLink ? (
              <WatchRecordingPrompt link={videoLink} />
            ) : !usefulDateIsPast(start, altCutOffHours ?? 3) ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {recordingWillBeAvailable === false && (
                  <span
                    style={{
                      fontWeight: 500,
                      marginRight: "20px",
                      color: "white",
                    }}
                    className={"notOnPhone"}
                  >
                    No recordings will be made available
                  </span>
                )}
                {!!altLink ? (
                  <StyledButton externalLink={altLink} classname="pink">
                    LEARN MORE & REGISTER (External Site)
                  </StyledButton>
                ) : (
                  <StyledButton onClick={openRegistrationModal} classname="pink">
                    REGISTER NOW!
                  </StyledButton>
                )}
              </div>
            ) : usefulDateIsPast(start, altCutOffHours ?? 3) && !usefulDateIsPast(start) ? (
              <StyledButton classname="disabled">Registration Closed</StyledButton>
            ) : recordingWillBeAvailable ? (
              <MissedRecordingPrompt />
            ) : recordingIsPurchasable ? (
              <PurchaseRecordingPrompt />
            ) : end !== undefined && !usefulDateIsPast(end) ? (
              <div>Happening Now!</div>
            ) : (
              <div>
                <StyledButton externalLink="https://forms.gle/A8GUQsYRsEKidrzG8" classname="pink">
                  Sign-Up For Future Info
                </StyledButton>
              </div>
            )}
          </div>
        </div>
        {recordingWillBeAvailable === false && (
          <span style={{ fontWeight: 500, color: "white" }} className={"phoneOnly"}>
            No recordings will be made available
          </span>
        )}
        <div className="detailsBody">
          <div />
          <div className="headerInfo">
            <DateTimeDisplayer date={start} secondDate={end} scheduleLink={scheduleLink} />
            <ContinuingEdCreditTag numberOfCredits={numberOfCredits} />
          </div>
          <div className="speakersArea">
            {speakers.map((speaker) => (
              <div key={speaker.name} className="individualSpeaker">
                <div className="speakerImage">{speaker.image}</div>
                <div className="speakerName">{speaker.name}</div>
                <div className="speakerExtraTitles">{speaker.extraTitling}</div>
                <div className="speakerAffiliation">{speaker.affiliation}</div>
              </div>
            ))}
          </div>
          <div className="aboutArea">
            <div className="talkTitle">{title}</div>
            {!!subtitle && <div className="talkSubtitle">{subtitle}</div>}
            <div className="intendedInstructionLevel">
              <span className="level">{instructionLevel.level} instruction level:</span>
              {instructionLevel.description}
            </div>
            <div className="aboutTheTalk">
              <div className="title">ABOUT THE {notAWebinar?.toUpperCase() ?? "WEBINAR"}</div>
              <div>{aboutSection}</div>
              <div>{aboutSpecial}</div>
            </div>
            <div className="educationalObjectives">
              <div className="title">EDUCATIONAL OBJECTIVES</div>
              <div>
                At the end of this {notAWebinar ?? "webinar"}, participants will be able to:
                {educationalObjectives.map((eduObjective) => (
                  <div key={eduObjective} className="objective">
                    {eduObjective}
                  </div>
                ))}
              </div>
            </div>
            {!!suggestedReadings && (
              <div className="educationalObjectives">
                <div className="title">RECOMMENDED READINGS</div>
                <div>
                  Before the {notAWebinar ?? "webinar"}, suggested readings include:
                  {suggestedReadings.map((reading) => (
                    <div key={reading} className="objective">
                      {reading}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="aboutTheSpeaker">
              <div className="title">ABOUT THE SPEAKER{speakers.length > 1 ? "S" : ""}</div>
              {speakers.map((speaker) => (
                <div key={speaker.name} className={"individualSpeaker"}>
                  {speaker.about}
                </div>
              ))}
            </div>
            <div className="interestConflicts">
              <div className="title">INTEREST CONFLICTS</div>
              {conflicts?.length ? (
                conflicts.map((conflict) => (
                  <div key={conflict} className="conflict">
                    {conflict}
                  </div>
                ))
              ) : (
                <div>No conflicts of interest arise. The speaker is receiving payment for the instruction.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)} title={"Registration"}>
          <div
            style={{
              marginBottom: "40px",
              padding: "20px",
              background: "#f9faf4",
            }}
          >
            In order to be awarded APA Continuing Education (CE) credit, attendees are required to do the following:
            <ul>
              <li>Attend the event in its entirety. No credit will be given for partial attendance.</li>
              <li> Complete an evaluation form of the event.</li>
            </ul>
          </div>
          <ButtonGroup className="right">
            {!!altLink ? (
              <StyledButton externalLink={altLink} classname="pink">
                LEARN MORE & REGISTER (External Site)
              </StyledButton>
            ) : (
              <>
                {purchaseOptions.highCost.cost !== "" && (
                  <StyledButton
                    addOn={`${purchaseOptions.highCost.cost === "FREE" ? "" : "$"}${purchaseOptions.highCost.cost}`}
                    externalLink={purchaseOptions.highCost.link}
                    classname="pink"
                  >
                    Register (APA CE)
                  </StyledButton>
                )}
                {purchaseOptions.lowCost && (
                  <StyledButton
                    addOn={`${purchaseOptions.lowCost.cost === "FREE" ? "" : "$"}${purchaseOptions.lowCost.cost}`}
                    externalLink={purchaseOptions.lowCost.link}
                    classname="pink"
                  >
                    Register
                  </StyledButton>
                )}
              </>
            )}
          </ButtonGroup>
        </Modal>
      )}
    </>
  );
};
