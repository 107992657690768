import React from "react";
import { DetailsPage } from "src/Components/Common/NEW/DetailsPage";
import { UsefulDate } from "src/utils";
import PerepletchikovaImg from "src/assets/Perepletchikova.jpeg";

const title = "Core Problem Analysis";
const subtitle =
  "Addressing internal controlling factors within the DBT-C model";

const lowCost = {
  cost: 15,
  link: "",
};
const highCost = {
  cost: 30,
  link: "",
};

const startTime: UsefulDate = {
  time: 12,
  month: "April",
  date: "25th",
  year: 2022,
};
const endTime: UsefulDate = {
  time: 13.5,
  month: "April",
  date: "25th",
  year: 2022,
};

const creditAmount = 1.5;

const speakerImage = (
  <img src={PerepletchikovaImg} alt={"Francheska Perepletchikova"} />
);

export const Perepletchikova = () => {
  return (
    <DetailsPage
      speakers={[
        {
          name: "Francheska Perepletchikova, Ph.D.",
          image: <div>{speakerImage}</div>,
          affiliation: "Youth Dialectical-Behavioral Program",
          about: (
            <div>
              Francheska Perepletchikova, Ph.D. is a Founding Director of the
              Youth-Dialectical Behavior Therapy Program that focuses on
              treatment of children, adolescents and young adults within the DBT
              model and provided training workshops in the approach. She
              received Ph.D. in Clinical Psychology from Yale University
              Department of Psychology in 2007. In 2015, she became a DBT
              trainer at Behavioral Tech, a Linehan Institute training company.
              In 2016 Dr. Perepletchikova became a DBT-Linehan Board of
              Certification Board Certified Clinician.
              <br />
              She has a long-standing interest in emotion regulation,
              trauma-related psychopathology, suicidality and non-suicidal
              self-injury, and psychotherapy development. From 2010, she focused
              on adapting DBT for pre-adolescent children with severe emotional
              and behavioral dysregulation. As a faculty at Yale University
              School of Medicine and at Weill Cornell Medical College, Dr.
              Perepletchikova conducted several RCTs to evaluate efficacy of DBT
              for children. This line of research has been funded by the grants
              from NIMH, NRSA and private foundations. Dr. Perepletchikova
              authored 30 journal articles and book chapters and a book
              “Treatment Integrity in Treatment Outcome Research” (2009). She
              conducted over 100 lectures, presentations and workshops around
              the world.
            </div>
          ),
        },
      ]}
      title={title}
      subtitle={subtitle}
      start={startTime}
      end={endTime}
      aboutSection={
        <div>
          Core Problem Analysis (CPA) is a part of Dialectical Behavioral
          Therapy for pre-adolescent children (DBT-C) intervention. CPA had been
          developed to address secondary targets of decreasing vulnerabilities
          in the children’s core senses of self-love, safety and belonging. Most
          children (and their parents) receiving DBT-C have a pronounced
          vulnerability in all of these senses. The sense of self-love refers to
          a stable and enduring sense of self-appreciation that is built on the
          realistic appraisals of one’s own abilities. Parental love and
          appreciation of a child is the foundation of developing self-love. The
          sense of safety implies feeling secure in the environment and
          realistically appraising dangers, instead of viewing the world as a
          threatening place. The sense of safety develops when the child has
          achieved control over her responses and has acquired sufficient life
          experiences to trust that she will be able to deal with challenges.
          The sense of belonging refers to a feeling of being welcomed and
          accepted as a part of a group.
          <br />A relationship where parents are validating and accepting is
          likely to help the child with developing a sense of belonging, while
          frequent criticism, pervasive judgments, and reliance on punishment
          are likely to communicate messages to the child that she is not
          accepted or even wanted. In DBT-C, the therapist assesses
          vulnerabilities in these core senses by conducting a Core Problem
          Analysis with children, as well as parents when needed, and directly
          targets decreasing vulnerabilities in these senses during and between
          sessions.
        </div>
      }
      educationalObjectives={[
        "Explain at least one function of Core Problem Analysis",
        "Describe DBT-C secondary targets ",
        "Differentiate between internal and external controlling factors in the DBT-C model",
      ]}
      numberOfCredits={creditAmount}
      instructionLevel={{
        level: "Intermediate",
        description:
          "This webinar is intended for those who have at least moderate experience in DBT",
      }}
      purchaseOptions={{
        highCost,
        lowCost,
      }}
    />
  );
};
